import {
	Box,
	Button,
	Link,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import LinkedAccountsFI from './LinkedAccountsFI';
import WhyShare from '../Sections/WhyShare';
import linkedAccountsSectionStyles from './LinkedAccountsSection.styles';
import DialogBox from '../Dialog Box/Dialog Box';
import DenyConsent from './DenyConsent';
import ConsentListContext from '../Context/ConsentContext';
import sizeFontCustom from '../Discovery/fontSize.styles';
import { getFiTypesHash } from '../../hepler/functions';

function LinkedAccountsSection(props) {
	const consentsContext = useContext(ConsentListContext);
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [deny, setDeny] = useState(false);
	const [checkedItems, setCheckedItems] = useState({});

	global.FIGroups = {
		'Bank Accounts': ['DEPOSIT'],
		'Deposits': ['TERM-DEPOSIT', 'RECURRING_DEPOSIT'],
		'Insurance': ['INSURANCE_POLICIES'],
		'Mutual Funds': ['MUTUAL_FUNDS'],
		'Equities': ['EQUITIES'],
		'GSTIN': ['GSTR1_3B'],
		'NPS': ['NPS'],
	};

	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick') {
			return;
		}
		setDeny(false);
	};

	let fiForConsent = [];
	let fiWithLinkedAccounts = {};
	let fiGroupsWithZeroAccts = [];
	const allConsentsFI = new Set();
	let similarConsentCount = 1;
	let consents = [];
	if (global.institutionType === 'LSP') {
		consents = consentsContext.selectedConsents;
	} else {
		consents = mobileScreen
			? [props?.consent]
			: [consentsContext.selectedConsents[0]];
		similarConsentCount = props?.consent
			? consentsContext.commonConsentFiTypesGroup[
					getFiTypesHash(props.consent.fiTypes)
			  ].size
			: 1;
	}
	consents.forEach((el) =>
		el?.fiTypes.forEach((fiType) => allConsentsFI.add(fiType))
	);
	fiForConsent = Object.keys(global.FIGroups).filter((key) =>
		global.FIGroups?.[key].every((fi) => allConsentsFI.has(fi))
	);
	if (fiForConsent.length > 1) {
		fiForConsent.sort((fiGroup1, fiGroup2) => {
			const noOfAccounts1 = props.linkedAccounts?.filter((account) =>
				global.FIGroups?.[fiGroup1].includes(account?.FIType)
			)?.length;
			const noOfAccounts2 = props.linkedAccounts?.filter((account) =>
				global.FIGroups?.[fiGroup2].includes(account?.FIType)
			)?.length;
			fiWithLinkedAccounts[fiGroup1] = noOfAccounts1;
			fiWithLinkedAccounts[fiGroup2] = noOfAccounts2;

			if (!noOfAccounts1 && !fiGroupsWithZeroAccts.includes(fiGroup1))
				fiGroupsWithZeroAccts.push(fiGroup1);
			if (!noOfAccounts2 && !fiGroupsWithZeroAccts.includes(fiGroup2))
				fiGroupsWithZeroAccts.push(fiGroup2);

			if (noOfAccounts1 === noOfAccounts2)
				return fiGroup1.localeCompare(fiGroup2);
			return noOfAccounts2 - noOfAccounts1;
		});
	} else if (fiForConsent.length === 1) {
		const noOfAccounts = props.linkedAccounts.filter((account) =>
			global.FIGroups?.[fiForConsent[0]].includes(account?.FIType)
		)?.length;
		fiWithLinkedAccounts[fiForConsent[0]] = noOfAccounts;
		if (!noOfAccounts) fiGroupsWithZeroAccts.push(fiForConsent[0]);
	}

	useEffect(() => {
		let checkAll = {};
		checkAll = props.linkedAccounts?.reduce((accumulator, obj) => {
			accumulator[obj.accRefNumber] = true;
			return accumulator;
		}, {});
		setCheckedItems(checkAll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCheckboxChange = (event, acc) => {
		// console.log(acc, "clicked")
		if (props?.consent) {
			props.handleSelect(acc, props?.consent?.ConsentHandle);
		} else {
			props.handleSelect(acc);
		}
		const { name, checked } = event.target;
		setCheckedItems((prevCheckedItems) => ({
			...prevCheckedItems,
			[name]: checked,
		}));
	};

	return (
		<Box
			sx={{
				padding: mobileScreen ? ' 0.5rem' : '5rem 1.875rem',
				backgroundColor: theme.palette.primary.light,
				gap: mobileScreen ? '1rem' : '1.5rem',
				...linkedAccountsSectionStyles.container,
			}}
		>
			<Box
				sx={{
					width: '100%',
					margin: {
						xs: '0 0 0.25rem 0rem',
						md: '1rem 0 0 0',
					},
				}}
			>
				<Box width={'100%'}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: mobileScreen ? 'flex-start' : 'center',
							flexDirection: mobileScreen ? 'column-reverse' : 'row',
						}}
					>
						<Typography sx={linkedAccountsSectionStyles.title}>
							Select Accounts
						</Typography>
						{!mobileScreen && (
							<Typography
								sx={{
									fontSize: '0.875rem',
									color: theme.palette.black60,
									fontFamily: props.customDetails.fontFamily,
								}}
							>
								{`AA handle: ${global?.userId}`}
							</Typography>
						)}
					</Box>
					<Typography
						color={theme.palette.black60}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							textAlign: 'start',
							// mt: '1rem',
							...sizeFontCustom.linkAccountsText,
						}}
					>
						Link and allow access from your accounts.{' '}
						{similarConsentCount - 1 > 0 &&
							similarConsentCount -
								1 +
								' other consent require similar accounts.'}
					</Typography>
				</Box>
				{global?.institutionType === 'LSP' && (
					<Typography
						color={theme.palette.black60}
						sx={{
							...sizeFontCustom.linkAccountsText,
							textAlign: 'start',
							mt: '1rem',
						}}
					>
						{consents.length + ' institutions have been selected. '}
						<Link
							color={theme.palette.primary.main}
							onClick={() => {
								props.history.push(`/select-lender`);
							}}
							sx={linkedAccountsSectionStyles.changeNumberLink}
						>
							Review.
							<br />
						</Link>
					</Typography>
				)}
			</Box>
			{/* fiGroups with > 0 linked accounts */}
			{fiForConsent.map((key) =>
				fiWithLinkedAccounts?.[key] > 0 ? (
					<LinkedAccountsFI
						key={key}
						name={key}
						checkedItems={checkedItems}
						handleCheckboxChange={handleCheckboxChange}
						requiredFI={fiForConsent}
						{...props}
					/>
				) : null
			)}
			{/* fiGroups with 0 linked accounts */}
			{fiGroupsWithZeroAccts.length ? (
				<LinkedAccountsFI
					name={'Add Other Accounts'}
					checkedItems={checkedItems}
					requiredFI={fiForConsent}
					fIGroupsWithZeroAccts={fiGroupsWithZeroAccts}
					{...props}
				/>
			) : null}
		</Box>
	);
}

export default LinkedAccountsSection;
