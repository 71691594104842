import React, { useState, useContext } from 'react';
import { Button } from '@mui/material';
import ConsentListContext from '../Context/ConsentContext';
import SouthIcon from '@mui/icons-material/South';

const FloatingButton = ({ numberOfUnviewedConsents, handleClick }) => {
	if (numberOfUnviewedConsents === 0) {
		return null;
	}
	const buttonText = `${numberOfUnviewedConsents} more consent${
		numberOfUnviewedConsents > 1 ? 's' : ''
	}`;

	return (
		<Button
			sx={{
				position: 'fixed',
				bottom: '8rem',
				transform: 'translateX(-50%)',
				left: '50%',
				fontSize: '0.675rem',
				zIndex: 1502,
				width: 'fit-content',
				textTransform: 'lowercase',
			}}
			variant="contained"
			size="small"
			color="primary"
			startIcon={<SouthIcon />}
			onClick={handleClick}
		>
			{buttonText}
		</Button>
	);
};

export default FloatingButton;
