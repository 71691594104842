import React, { useContext, useEffect, useRef, useState } from 'react';
import ConsentListContext from './Context/ConsentContext';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'react-toastify';
import RequestedConsents from './paymentConsent/RequestedConsents';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import LinkedAccountsSection from './Accounts/LinkedAccountsSection';
import SubmittingConsent from './paymentConsent/SubmittingConsent';
// import ConsentCard from './paymentConsent/ConsentCard';
import ConsentCardFull from './paymentConsent/ConsentCardFull/ConsentCardFull';
import linkedAccountsSectionStyles from './Accounts/LinkedAccountsSection.styles';
import DialogBox from './Dialog Box/Dialog Box';
import WhyShare from './Sections/WhyShare';
import DenyConsent from './Accounts/DenyConsent';
import { areAllArraysEmptyForConsents } from '../hepler/functions';
import FloatingButton from './Fab/FloatingButton';

function PaymentConsent(props) {
	const consentsContext = useContext(ConsentListContext);
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const consentsAll = consentsContext.consentList;
	const consentsAccepted = consentsContext.acceptedConsentsMultiConsent;
	const consentsDenied = consentsContext.deniedConsentsMultiConsent;
	const [state, setState] = useState({
		linkedAccounts: [],
		selectedAccounts: [],
		consentDetailsList: consentsContext?.consentList,
		isDisable: false,
		consentSelectedAccounts: [],
	});

	// console.log('State: ', state);
	const authApis = new AuthApis();
	const SUBMITTING_CONSENT_COUNTER =
		consentsAll.length > 1 && global.institutionType !== 'LSP' ? 1000 : 0;
	const isFIUMobileScreen = global.institutionType !== 'LSP' && mobileScreen;

	//New
	const [selectedConsentsState, setSelectedConsentsState] = useState(
		consentsAll.map((a) => {
			return a?.ConsentHandle;
		})
	);
	// console.log('selectedConsentsState:', selectedConsentsState);
	// console.log('ALL STATE: ', state);
	// console.log('context selectedConsents:', consentsContext.selectedConsents);

	const [deny, setDeny] = useState(false);
	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick') {
			return;
		}
		setDeny(false);
	};

	const selectUnselectConsents = (consent) => {
		// console.log('selectUnselectConsents consent param: ', consent);
		setSelectedConsentsState((prevConsents) => {
			// console.log('selectUnselectConsents prevConsents:', prevConsents);
			if (prevConsents.includes(consent)) {
				const index = prevConsents.indexOf(consent);
				prevConsents.splice(index, 1);
			} else {
				prevConsents.push(consent);
			}
			consentsContext.updateSelectedConsentList(
				consentsAll.filter((x) => prevConsents.includes(x.ConsentHandle))
			);
			return [...prevConsents];
		});
	};

	useEffect(() => {
		if (global?.institutionType === 'LSP') {
			consentsContext.updateActiveSlide(1);
		}
		if (props?.location?.state?.data?.length) {
			const consentSelections = setConsentSelectedAccount(
				props?.location?.state?.data
			);
			setState({
				...state,
				linkedAccounts: props?.location?.state?.data,
				selectedAccounts: [...props?.location?.state?.data],
				consentSelectedAccounts: consentSelections,
			});
			props.updateLoader(false);
			// consentRequestDetails()
		} else {
			props.updateLoader(true);
			getLinkedAcc();
		}
	}, []);

	const setConsentSelectedAccount = (accounts) => {
		const res = {};
		for (const consent of consentsAll) {
			res[consent?.ConsentHandle] = [...accounts];
		}
		return res;
	};

	const getLinkedAcc = () => {
		authApis.getLinkedAccounts().then((res) => {
			props.updateLoader(false);
			if (res?.status === 'SUCCESS') {
				// let data = res.linkedAccounts.filter(obj => consentDetails?.fiTypes.includes(obj.FIType))
				setState({
					...state,
					linkedAccounts: res?.LinkedAccounts,
					selectedAccounts: [...res?.LinkedAccounts],
					consentDetailsList: consentsContext.consentList,
					consentSelectedAccounts: setConsentSelectedAccount(
						res?.LinkedAccounts
					),
				});
			} else {
				props.updateLoader(false);
				toast.error(res?.message);
				console.log(res?.message);
				if (res?.status === 'RECORD-NOT-FOUND') {
					props.history.push(
						`/home/?number=${
							global.mobileNumber
						}&isAddAccount=${true}&isNewUser=${true}`
					);
				} else {
					if (res?.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res?.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
					setTimeout(() => {
						props.history.push({
							pathname: `/consent-status`,
							state: {
								statusCode: 403,
							},
						});
					}, 1000);
				}
				// props.history.push(`/error`)
			}
		});
	};

	const updateContext = (consentHandle, status) => {
		if (status === 'ACCEPT') {
			consentsAccepted.push(consentHandle);
			consentsContext.updateAcceptedConsentsMultiConsent(consentsAccepted);
		} else {
			consentsDenied.push(consentHandle);
			consentsContext.updateDeniedConsentsMultiConsent(consentsDenied);
		}
		consentsAll.push(consentsAll.shift());
		consentsContext.updateConsentList(consentsAll);
	};

	const consentApproveRequest = async (
		accounts,
		status,
		consentHandle,
		fiuID,
		isLast
	) => {
		setState({ ...state, isDisable: true });
		await authApis
			.consentApproveRequest(accounts, status, consentHandle, fiuID)
			.then((res) => {
				if (res && res?.status === 'SUCCESS') {
					if (isLast) {
						setTimeout(() => {
							updateContext(consentHandle, status);
							toast.success(
								status === 'ACCEPT' ? 'Consent approved' : 'Consent denied'
							);
							props.history.push({
								pathname: `/consent-status`,
								state: {
									status:
										status === 'ACCEPT' ? 'Consent approved' : 'Consent denied',
									statusCode: status === 'ACCEPT' ? 200 : 201,
								},
							});
						}, SUBMITTING_CONSENT_COUNTER);
					} else {
						setTimeout(() => {
							updateContext(consentHandle, status);
							toast.success(
								status === 'ACCEPT' ? 'Consent approved' : 'Consent denied'
							);
							setState({ ...state, isDisable: false });
						}, SUBMITTING_CONSENT_COUNTER);
					}
				} else {
					toast.error(res?.message);
					console.log(res?.message);
					if (res?.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res?.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
					setTimeout(() => {
						props.history.push({
							pathname: `/consent-status`,
							state: {
								status: 'Consent denied',
								statusCode: 403,
							},
						});
					}, 500);
				}
			});
	};

	const handleAcceptFiuMultiple = async () => {
		const consents = consentsContext?.selectedConsents;
		if (consents && consents.length > 0) {
			if (
				areAllArraysEmptyForConsents(
					state.consentSelectedAccounts,
					selectedConsentsState
				)
			) {
				toast.error('Select at least one account a selected consent!');
				return;
			} else {
				let continueApprove = true;
				const consentAccounts = [];

				for (let k = 0; k < consents?.length; k++) {
					let requiredAccountsSelected = [];
					const requiredFI = consents[k].fiTypes;
					const selectedAccounts =
						state.consentSelectedAccounts[consents[k].ConsentHandle];
					let fips = selectedAccounts
						.filter((el) => requiredFI.includes(el?.FIType))
						.map((el) => el?.fipId);
					fips = [...new Set(fips)];
					for (let j = 0; j < fips?.length; j++) {
						let withFip = {
							FIP: {
								id: fips[j],
							},
							Accounts: [],
						};
						for (let i = 0; i < selectedAccounts?.length; i++) {
							if (requiredFI.includes(selectedAccounts[i]?.FIType)) {
								if (fips[j] === selectedAccounts[i]?.fipId) {
									let single = {
										linkRefNumber: selectedAccounts[i]?.linkRefNumber,
										accType: selectedAccounts[i]?.accType,
										accRefNumber: selectedAccounts[i]?.accRefNumber,
										maskedAccNumber: selectedAccounts[i]?.maskedAccNumber,
										FIType: selectedAccounts[i]?.FIType,
										fipId: selectedAccounts[i]?.fipId,
										fipName: selectedAccounts[i]?.fipName,
									};
									withFip.Accounts.push(single);
								}
							}
						}
						if (withFip.Accounts.length > 0) {
							requiredAccountsSelected.push(withFip);
						}
					}
					if (requiredAccountsSelected.length === 0) {
						toast.error('No relevant account selected/added');
						continueApprove = false;
					} else {
						consentAccounts.push({
							ConsentHandle: consents[k].ConsentHandle,
							fiuId: consents[k].FIU.id,
							Accounts: requiredAccountsSelected,
						});
					}
				}
				if (continueApprove) {
					setState({ isDisable: true, disableType: 'ACCEPT' });
					let isLast = false;
					for (let k = 0; k < consentAccounts?.length; k++) {
						if (k === consentAccounts?.length - 1) {
							isLast = true;
						}
						await consentApproveRequest(
							consentAccounts[k].Accounts,
							'ACCEPT',
							consentAccounts[k].ConsentHandle,
							consentAccounts[k].fiuId,
							isLast
						);
					}
				}
			}
		} else {
			toast.error('No consents selected!');
			return;
		}
	};

	const handleAcceptFiuMultipleWeb = async () => {
		const consents = consentsContext?.selectedConsents;
		if (consents && consents.length > 0) {
			if (state.selectedAccounts?.length > 0) {
				let continueApprove = true;
				const consentAccounts = [];

				// console.log('Selected Consents from context: ', consents);
				// console.log('Selected accounts from state: ', state.selectedAccounts);
				for (let k = 0; k < consents?.length; k++) {
					let requiredAccountsSelected = [];
					const requiredFI = consents[k].fiTypes;
					let fips = state.selectedAccounts
						.filter((el) => requiredFI.includes(el?.FIType))
						.map((el) => el?.fipId);
					fips = [...new Set(fips)];
					for (let j = 0; j < fips?.length; j++) {
						let withFip = {
							FIP: {
								id: fips[j],
							},
							Accounts: [],
						};
						for (let i = 0; i < state.selectedAccounts?.length; i++) {
							if (requiredFI.includes(state.selectedAccounts[i]?.FIType)) {
								if (fips[j] === state.selectedAccounts[i]?.fipId) {
									let single = {
										linkRefNumber: state.selectedAccounts[i]?.linkRefNumber,
										accType: state.selectedAccounts[i]?.accType,
										accRefNumber: state.selectedAccounts[i]?.accRefNumber,
										maskedAccNumber: state.selectedAccounts[i]?.maskedAccNumber,
										FIType: state.selectedAccounts[i]?.FIType,
										fipId: state.selectedAccounts[i]?.fipId,
										fipName: state.selectedAccounts[i]?.fipName,
									};
									withFip.Accounts.push(single);
								}
							}
						}
						if (withFip.Accounts.length > 0) {
							requiredAccountsSelected.push(withFip);
						}
					}
					if (requiredAccountsSelected.length === 0) {
						toast.error('No relevant account selected/added');
						continueApprove = false;
					} else {
						consentAccounts.push({
							ConsentHandle: consents[k].ConsentHandle,
							fiuId: consents[k].FIU.id,
							Accounts: requiredAccountsSelected,
						});
					}
				}
				// console.log('consentAccounts variable: ', consentAccounts);
				if (continueApprove) {
					setState({ isDisable: true, disableType: 'ACCEPT' });
					let isLast = false;
					for (let k = 0; k < consentAccounts?.length; k++) {
						if (k === consentAccounts?.length - 1) {
							isLast = true;
						}
						await consentApproveRequest(
							consentAccounts[k].Accounts,
							'ACCEPT',
							consentAccounts[k].ConsentHandle,
							consentAccounts[k].fiuId,
							isLast
						);
					}
				}
			} else {
				toast.error('No account selected/added');
				return;
			}
		} else {
			toast.error('No consents selected!');
			return;
		}
	};

	const handleAcceptLsp = async () => {
		if (state.selectedAccounts?.length > 0) {
			let continueApprove = true;
			const consentAccounts = [];
			const consents = consentsContext?.selectedConsents;
			for (let k = 0; k < consents?.length; k++) {
				let requiredAccountsSelected = [];
				const requiredFI = consents[k].fiTypes;
				let fips = state.selectedAccounts
					.filter((el) => requiredFI.includes(el?.FIType))
					.map((el) => el?.fipId);
				fips = [...new Set(fips)];
				for (let j = 0; j < fips?.length; j++) {
					let withFip = {
						FIP: {
							id: fips[j],
						},
						Accounts: [],
					};
					for (let i = 0; i < state.selectedAccounts?.length; i++) {
						if (requiredFI.includes(state.selectedAccounts[i]?.FIType)) {
							if (fips[j] === state.selectedAccounts[i]?.fipId) {
								let single = {
									linkRefNumber: state.selectedAccounts[i]?.linkRefNumber,
									accType: state.selectedAccounts[i]?.accType,
									accRefNumber: state.selectedAccounts[i]?.accRefNumber,
									maskedAccNumber: state.selectedAccounts[i]?.maskedAccNumber,
									FIType: state.selectedAccounts[i]?.FIType,
									fipId: state.selectedAccounts[i]?.fipId,
									fipName: state.selectedAccounts[i]?.fipName,
								};
								withFip.Accounts.push(single);
							}
						}
					}
					if (withFip.Accounts.length > 0) {
						requiredAccountsSelected.push(withFip);
					}
				}
				if (requiredAccountsSelected.length === 0) {
					toast.error('No relevant account selected/added');
					continueApprove = false;
				} else {
					consentAccounts.push({
						ConsentHandle: state.consentDetailsList[k].ConsentHandle,
						fiuId: state.consentDetailsList[k].FIU.id,
						Accounts: requiredAccountsSelected,
					});
				}
			}
			if (continueApprove) {
				setState({ isDisable: true, disableType: 'ACCEPT' });
				let isLast = false;
				for (let k = 0; k < consentAccounts?.length; k++) {
					if (k === consentAccounts?.length - 1) {
						isLast = true;
					}
					await consentApproveRequest(
						consentAccounts[k].Accounts,
						'ACCEPT',
						consentAccounts[k].ConsentHandle,
						consentAccounts[k].fiuId,
						isLast
					);
				}
			}
		} else {
			toast.error('No account selected/added');
		}
	};

	const handleAcceptFiu = async () => {
		if (state.selectedAccounts?.length > 0) {
			const consent = consentsContext.selectedConsents[0];
			let requiredAccountsSelected = [];
			const requiredFI = consent?.fiTypes;
			let fips = state.selectedAccounts
				.filter((el) => requiredFI.includes(el?.FIType))
				.map((el) => el?.fipId);
			fips = [...new Set(fips)];
			for (let j = 0; j < fips?.length; j++) {
				let withFip = {
					FIP: {
						id: fips[j],
					},
					Accounts: [],
				};
				for (let i = 0; i < state.selectedAccounts?.length; i++) {
					if (requiredFI.includes(state.selectedAccounts[i]?.FIType)) {
						if (fips[j] === state.selectedAccounts[i]?.fipId) {
							let single = {
								linkRefNumber: state.selectedAccounts[i]?.linkRefNumber,
								accType: state.selectedAccounts[i]?.accType,
								accRefNumber: state.selectedAccounts[i]?.accRefNumber,
								maskedAccNumber: state.selectedAccounts[i]?.maskedAccNumber,
								FIType: state.selectedAccounts[i]?.FIType,
								fipId: state.selectedAccounts[i]?.fipId,
								fipName: state.selectedAccounts[i]?.fipName,
							};
							withFip.Accounts.push(single);
						}
					}
				}
				if (withFip.Accounts.length > 0) {
					requiredAccountsSelected.push(withFip);
				}
			}
			console.log(requiredAccountsSelected, 'required ACcounts');
			if (requiredAccountsSelected.length === 0) {
				toast.error('No relevant account selected/added');
			} else {
				const isLast =
					consentsAll.length -
						consentsAccepted.length -
						consentsDenied.length ===
					1
						? true
						: false;
				await consentApproveRequest(
					requiredAccountsSelected,
					'ACCEPT',
					consent.ConsentHandle,
					consent.FIU.id,
					isLast
				);
			}
		} else {
			toast.error('No relevant account selected/added');
		}
	};

	const handleDeny = async () => {
		setState({ ...state, isDisable: true });
		let isLast =
			consentsAll.length - consentsAccepted.length - consentsDenied.length === 1
				? true
				: false;
		const consents =
			global.institutionType === 'FIU' && consentsAll.length === 1
				? [consentsAll[0]]
				: consentsContext.consentList;
		if (global.institutionType === 'FIU' && consentsAll.length === 1) {
			await consentApproveRequest(
				[],
				'DENY',
				consents[0].ConsentHandle,
				consents[0].FIU.id,
				isLast
			);
		} else {
			for (let k = 0; k < consents.length; k++) {
				if (k === consents.length - 1) {
					isLast = true;
				}
				await consentApproveRequest(
					[],
					'DENY',
					consents[k].ConsentHandle,
					consents[k].FIU.id,
					isLast
				);
			}
			consentsContext.updateConsentList([]);
		}
	};

	const updateAccounts = (el, data) => {
		if (data.includes(el)) {
			const index = data.indexOf(el);
			data.splice(index, 1);
		} else {
			data.push(el);
		}
		return data;
	};

	const selectUnselectAcc = async (el, consentHandle = null) => {
		let data = updateAccounts(el, [...state.selectedAccounts]);
		if (consentHandle) {
			const consentData = updateAccounts(el, [
				...state.consentSelectedAccounts[consentHandle],
			]);
			const accounts = state.consentSelectedAccounts;
			accounts[consentHandle] = consentData;
			setState({
				...state,
				selectedAccounts: data,
				consentSelectedAccounts: accounts,
			});
			return;
		}
		setState({ ...state, selectedAccounts: data });
	};

	const handleAccept = () => {
		if (global.institutionType === 'FIU') {
			if (consentsAll.length === 1) return handleAcceptFiu();
			else if (consentsAll.length > 1 && mobileScreen)
				return handleAcceptFiuMultiple();
			else handleAcceptFiuMultipleWeb();
		} else {
			return handleAcceptLsp();
		}
	};

	//Floating Button logic
	const [unviewedConsents, setUnviewedConsents] = useState(
		consentsAll.length - 1
	);
	const consentListRef = useRef();

	useEffect(() => {
		const handleScroll = () => {
			const consentListElement = consentListRef.current;
			// console.log('consentListElement: ', consentListElement);
			if (!consentListElement) return;

			//Calculate the number of consents viewed
			const scrollTop = window.scrollY;
			// console.log('scrollTop: ', scrollTop);
			const clientHeight = window.innerHeight;
			// console.log('clientHeight:', clientHeight);
			const scrollHeight = document.documentElement.scrollHeight;
			// console.log('scrollHeight:', scrollHeight);

			let viewedConsents;

			const consentCardHeight = consentListElement.firstChild.clientHeight;
			const consentCardIndex = Math.floor(scrollTop / consentCardHeight);
			viewedConsents = consentCardIndex + 1;

			// console.log('Viewed consents:', viewedConsents);

			//Update the number of unviewed consents
			setUnviewedConsents(consentsAll.length - viewedConsents);
		};

		window.addEventListener('scroll', handleScroll);

		handleScroll();

		return () => {
			//Cleanup: remove scroll event listener
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleMoreConsentsClick = () => {
		const consentListElement = consentListRef.current;
		if (!consentListElement) return;

		// console.log(unviewedConsents);
		const nextConsentIndex = consentsAll.length - unviewedConsents;
		// console.log('nextConsentIndex: ', nextConsentIndex);
		const nextConsentElement = consentListElement.children[nextConsentIndex];
		nextConsentElement.scrollIntoView({ behavior: 'smooth' });
		nextConsentElement.focus();
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: { xs: 'start', md: 'center' },
				flexDirection: { xs: 'column', sm: 'column', md: 'row' },
				minHeight: { xs: 'auto', md: '100vh' },
				alignItems: 'start',
			}}
		>
			<RequestedConsents
				display={'flex'}
				customDetails={props.customDetails}
				consentDetailsList={state?.consentDetailsList}
				selectedConsentsState={selectedConsentsState}
				handleIsActive={selectUnselectConsents}
				{...props}
			/>
			{isFIUMobileScreen && (
				<div
					ref={consentListRef}
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
						width: '100%',
					}}
				>
					{consentsAll.map((consent, idx) => (
						<ConsentCardFull
							key={idx}
							customDetails={props.customDetails}
							consentDetails={consent}
							id={idx + 1}
							selectedConsentsState={selectedConsentsState}
							handleIsActive={selectUnselectConsents}
							isMultiConsent={consentsAll?.length > 1}
							isLast={idx === consentsAll.length - 1}
						>
							<LinkedAccountsSection
								handleSelect={selectUnselectAcc}
								superState={state}
								linkedAccounts={state.linkedAccounts}
								customDetails={props.customDetails}
								consentDetails={
									state?.consentDetailsList || global.selectedConsent
								}
								selectedAccounts={state.selectedAccounts}
								handleAccept={handleAccept}
								handleDeny={handleDeny}
								consent={consent}
								{...props}
							/>
						</ConsentCardFull>
					))}
					<FloatingButton
						numberOfUnviewedConsents={unviewedConsents}
						handleClick={handleMoreConsentsClick}
					/>
					<div>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								width: '100%',
							}}
						>
							<Box
								sx={{
									// mr: '-0.5rem',
									bottom: mobileScreen ? '0rem' : '1.875rem',
									right: mobileScreen ? '0rem' : '0rem',
									backgroundColor: mobileScreen ? 'white' : 'none',
									gap: mobileScreen ? '1rem' : '1.25rem',
									width: mobileScreen ? '100vw' : '20%',
									flexWrap: 'wrap',
									...linkedAccountsSectionStyles.buttonGroup,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={mobileScreen}
									color="error"
									onClick={() => setDeny(true)}
									sx={{ flex: 1, background: theme.palette.primary.white }}
								>
									Deny
								</Button>
								<Button
									variant="contained"
									fullWidth={mobileScreen}
									disabled={unviewedConsents > 0}
									onClick={() => handleAccept()}
									sx={{
										backgroundColor: props.customDetails.primaryColor,
										flex: 1,
									}}
								>
									Accept
								</Button>
								{mobileScreen ? <WhyShare {...props} /> : null}
							</Box>
						</Box>
					</div>
				</div>
			)}
			{!isFIUMobileScreen &&
				(!state.isDisable ? (
					<>
						<LinkedAccountsSection
							handleSelect={selectUnselectAcc}
							superState={state}
							linkedAccounts={state.linkedAccounts}
							customDetails={props.customDetails}
							consentDetails={
								state?.consentDetailsList || global.selectedConsent
							}
							selectedAccounts={state.selectedAccounts}
							handleAccept={handleAccept}
							handleDeny={handleDeny}
							{...props}
						/>

						<div>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									width: '100%',
								}}
							>
								<Box
									sx={{
										// mr: '-0.5rem',
										bottom: mobileScreen ? '0rem' : '1.875rem',
										right: mobileScreen ? '0rem' : '0rem',
										backgroundColor: mobileScreen ? 'white' : 'none',
										columnGap: mobileScreen ? '1rem' : '1.25rem',
										rowGap: mobileScreen ? '0.75rem' : '1.25rem',
										width: mobileScreen ? '100vw' : '20%',
										flexWrap: 'wrap',
										...linkedAccountsSectionStyles.buttonGroup,
									}}
								>
									<Button
										variant="outlined"
										fullWidth={mobileScreen}
										color="error"
										onClick={() => setDeny(true)}
										sx={{ flex: 1, background: theme.palette.primary.white }}
									>
										Deny
									</Button>
									<Button
										variant="contained"
										fullWidth={mobileScreen}
										// disabled={props.superState.isDisable}
										onClick={() => handleAccept()}
										sx={{
											backgroundColor: props.customDetails.primaryColor,
											flex: 1,
										}}
									>
										Accept
									</Button>
									{mobileScreen ? <WhyShare {...props} /> : null}
								</Box>
							</Box>
						</div>
					</>
				) : (
					<SubmittingConsent />
				))}
			<DialogBox
				open={deny}
				handleClose={handleClose}
				title="Deny Consent"
				subTitle="Are you sure you want to deny this consent."
				boxPadding="1.5rem"
				subTitleMaxWidth="85%"
			>
				<DenyConsent
					handleCancel={() => setDeny(false)}
					{...props}
					handleDeny={handleDeny}
				/>
			</DialogBox>
		</Box>
	);
}

export default PaymentConsent;
