import sizeFontCustom from '../Discovery/fontSize.styles';

const linkedAccountsSectionStyles = {
	buttonGroup: {
		justifyContent: 'flex-end',
		padding: '1rem',
		zIndex: 1051,
		position: 'fixed',
		display: 'flex',
	},
	container: {
		flex: '2',
		display: 'flex',
		justifyContent: { xs: 'start', md: 'start' },
		flexDirection: 'column',
		minHeight: { xs: 'auto', md: '100vh' },
		alignItems: 'start',
		// overflowY:"scroll",
	},
	title: {
		...sizeFontCustom.titleMain,
		fontWeight: '900',
	},
	subTitle: {
		...sizeFontCustom.heading,
		textAlign: 'left',
		margin: {
			xs: '-0.75rem 0 0.25rem 0.5rem',
			md: '-1rem 0 0 0',
		},
	},
	changeNumberLink: {
		cursor: 'pointer',
		textDecoration: 'none',
		fontWeight: '500',
		textTransform: 'capitalize',
		...sizeFontCustom.cardTitle,
	},
};
export default linkedAccountsSectionStyles;
