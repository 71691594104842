/* eslint-disable react/prop-types */
import { Grid, Typography } from "@mui/material";
function ConsentDetailSingleDialog({ title, children, xs }) {
  // const theme = useTheme();
  return (
    <Grid
      item
      xs={xs ? xs : 5.7}
      display={"flex"}
      sx={{
        borderRadius: "12px",
      }}
    >
      <Typography
        sx={{
          flex: 1,
          fontSize: "0.875rem",
          color: "#81858F",
          marginBottom: "0.6rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          flex: xs ? 3 : 1,
          ml: "0.25rem",
          wordWrap: "break-word",
          fontSize: "0.875rem",
          fontWeight: 500,
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
}

export default ConsentDetailSingleDialog;
