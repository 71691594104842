import { Box, useMediaQuery, useTheme } from '@mui/material';
import ConsentCard from './ConsentCard';
import { useContext, useState } from 'react';
import WhyShare from '../Sections/WhyShare';
import RequestedConsentsHeading from '../Sections/RequestedConsentsHeading';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import './styles.css';

// import required modules
import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import ConsentListContext from '../Context/ConsentContext';
import LspSteps from './LspSteps';
import LspLogoGroup from './LspLogoGroup';
import ConsentFitypesChipSet from './ConsentFitypesChipSet';
import ChangeSlide from './SwiperSlideChange';
import ConsentCardFull from './ConsentCardFull/ConsentCardFull';

function RequestedConsents(props) {
	const consentContext = useContext(ConsentListContext);
	// console.log(consentContext, 'consentContext');
	const [isActive, setIsActive] = useState(ConsentListContext?.activeSlide);
	const selectedConsents =
		global?.institutionType === 'LSP' ? consentContext?.selectedConsents : [];
	const handleCardClick = (id, consent) => {
		global.consent = consent;
		setIsActive(id);
	};
	const consentsAll = consentContext?.consentList
		? consentContext?.consentList
		: [];
	const lenders =
		global?.institutionType === 'LSP'
			? consentsAll.map((consent) => consent?.entityInfoDetails)
			: [];
	const consentsNum = consentsAll?.length;
	const fiTypesAll = new Set();
	selectedConsents.forEach((consent) =>
		consent?.fiTypes?.forEach((fiType) => fiTypesAll.add(fiType))
	);
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isFIUMobileScreen = global.institutionType !== 'LSP' && mobileScreen;
	return (
		<Box
			sx={{
				flex: 1,
				position: mobileScreen ? 'relative' : 'sticky',
				top: '1px',
				display: props.display ? props.display : mobileScreen ? 'none' : 'flex',
				justifyContent: 'start',
				flexDirection: { xs: 'column', sm: 'column', md: 'column' },
				minHeight: { xs: 'auto', md: '100vh' },
				alignItems: 'start',
				paddingTop: '4.75rem',
				width: '100%',
				maxWidth: 'fit-content',
				'::after': {
					width: '60%',
					height: '5px',
					background: 'red',
					position: 'absolute',
					bottom: '-5px',
					left: '0',
				},
			}}
		>
			<RequestedConsentsHeading {...props} consentsNum={consentsNum} />
			{!isFIUMobileScreen && (
				<Box
					width={'100%'}
					height={mobileScreen ? 'auto' : 'calc(77vh - 83px)'}
					overflow={'hidden'}
				>
					<Swiper
						initialSlide={0}
						slidesPerView={'auto'}
						direction={!mobileScreen ? 'vertical' : 'horizontal'}
						observer={'true'}
						observeslidechildren={'true'}
						freeMode={true}
						pagination={{
							clickable: 'false',
						}}
						mousewheel={true}
						modules={[FreeMode, Mousewheel, Pagination]}
						className="mySwiper"
					>
						<ChangeSlide position={consentContext?.activeSlide} />
						{global?.institutionType !== 'LSP' ? (
							consentsAll.map((consent, idx) => (
								<SwiperSlide key={idx} className="cardSlide">
									<ConsentCardFull
										key={idx}
										isActive={isActive}
										handleIsActive={props.handleIsActive}
										customDetails={props.customDetails}
										selectedConsentsState={props.selectedConsentsState}
										consentDetails={consent}
										id={idx + 1}
										isMultiConsent={consentsAll?.length > 1}
									/>
								</SwiperSlide>
							))
						) : (
							<>
								<SwiperSlide className="cardSlide">
									<LspSteps
										title="Select Lenders"
										subtitle={
											lenders?.length +
											' institutions have requested for information'
										}
										isActive={isActive}
										lenders={lenders}
										handleIsActive={handleCardClick}
										customDetails={props.customDetails}
										id={1}
									>
										<LspLogoGroup lenders={lenders} />
									</LspSteps>
								</SwiperSlide>
								<SwiperSlide className="cardSlide">
									<LspSteps
										title="Select Accounts"
										subtitle={fiTypesAll?.size + ' Account types are requested'}
										isActive={isActive}
										lenders={lenders}
										customDetails={props.customDetails}
										id={2}
									>
										<ConsentFitypesChipSet fiTypes={[...fiTypesAll]} />
									</LspSteps>
								</SwiperSlide>
							</>
						)}
					</Swiper>
				</Box>
			)}
			{!mobileScreen ? <WhyShare {...props} /> : null}
		</Box>
	);
}

export default RequestedConsents;
