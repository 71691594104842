import { Avatar, AvatarGroup, useTheme } from '@mui/material'
import React from 'react'
import lenderStyles from './Lenders.styles'

function LspLogoGroup({lenders}) {
  const theme = useTheme();
  return (
      <AvatarGroup max={5} spacing={24} sx={lenderStyles.avatarGrp}>
          {lenders?.map((lender,idx) => (
            <Avatar
              key={idx}
              alt={lender?.entityLogoName}
              src={lender?.entityLogoUri}
              sx={{
                ...lenderStyles.avatar,
                bgcolor: `${theme.palette.primary.light}`,
                boxShadow: `${theme.shadows[4]}`,
              }}
            />
          ))}
      </AvatarGroup>  )
}

export default LspLogoGroup