const loginStyles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-betweem',
		flex: 1,
		padding: {
			xs: '6rem 1.5rem',
			sm: '6rem 1.5rem',
			md: '12.75rem 5.5rem 7.125rem  5.5rem',
		},
		minHeight: { xs: 'auto', md: '100vh' },
		position: 'relative',
		width: '100%',
	},
	subHeading: {
		lineHeight: '145%',
		fontSize: { xs: '0.875rem', md: '1rem' },
		m: '1rem 1.23rem 0 0',
		display: 'flex',
		flex: { xs: 3, md: 'unset' },
		alignItems: 'center',
		fontWeight: 400,
	},
	otpContainer: { gap: '1.5rem', alignItems: 'flex-start' },
	otpText: {
		textAlign: 'left',
		fontSize: { xs: '0.75rem', md: '0.875rem' },
		width: '100%',
		borderRadius: '12px',
	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	headingText: {
		fontSize: { xs: '1rem', md: '1.25rem' },
		fontWeight: 800,
	},
	inputContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
		gap: '0.75rem',
	},
	tcLabel: { whiteSpace: 'pre-wrap', display: 'flex' },
	tncLabel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		fontSize: '0.875rem',
		fontWeight: 400,
		mt: '0.5rem',
		flexWrap: 'wrap',
	},
	footerContainer: {
		position: 'absolute',
		width: '100%',
		bottom: 0,
		left: 0,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		p: '0.875rem',
		justifyContent: 'space-around',
		gap: '10px',
	},
	footerContainerMobile: {
		width: '100%',
		bottom: 0,
		left: 0,
		display: 'flex',
		alignItems: 'center',
		height: '0.5rem',
		pt: '0.875rem',
	},
	footerTextNew: {
		fontSize: '10px',
		fontWeight: 400,
		// lineHeight: '145%',
	},
	footerText: {
		fontSize: '12px',
		fontWeight: 400,
		lineHeight: '145%',
	},
	lockIconMobile: {
		width: '16px',
		height: '16px',
		'& .MuiAvatar-img': {
			objectFit: 'contain',
		},
		color: 'green',
	},
	lockIcon: {
		width: '25px',
		height: '25px',
		'& .MuiAvatar-img': {
			objectFit: 'contain',
		},
		color: 'green',
	},
};

export default loginStyles;
