import { Avatar, Box, Checkbox, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import stylesLogo from '../Accounts/Account.styles';
import sizeFontCustom from "../Discovery/fontSize.styles"

function FrequentFip(props) {
  const { fipId, popular, handleCheckboxChange, selectedFipList } = props;
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      item
      
      xs={5.75}
      sm={5.75}
      md={5.75}
      lg={2.75}
      padding={mobileScreen ? "0.75rem" : popular ? "1rem 1.25rem" : "1rem 1rem"}
      // margin={"0 0 0 0"}
      sx={{
        display: mobileScreen ? "flex" : "block",
        justifyContent: "space-between",
        alignItems: "center",
        background: `${theme.palette.primary.white}`,
        borderRadius: "20px",
      }}
    >
      <Box  onClick={(e) => handleCheckboxChange(e, fipId)} display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar alt={fipId} src={global.objectFormatFip[fipId].productIconUri} sx={stylesLogo.avatarStyle} />
          <Typography sx={sizeFontCustom.subHeading} textAlign={"start"}>
            {global.objectFormatFip[fipId].productName.split('_').join(' ')}
          </Typography>
        </Box>
        <Checkbox
          disableTouchRipple
          disableRipple
          name={fipId}
          value={fipId}
          size={mobileScreen ? "small" : popular ? "medium" : "small"}
          sx={{ height: "18px", width: "18px", ml: "0.675rem" }}
          checked={selectedFipList.includes(fipId)}
          onChange={(e) => handleCheckboxChange(e, fipId)}
        />
      </Box>
    </Grid>
  )
}

export default FrequentFip