import { Avatar, Box, Button, CardActionArea, Checkbox, Divider, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import EastIcon from '@mui/icons-material/East';
import {  dateFormat } from "../../hepler/functions";
import Drawer from "../Drawer/Drawer";
import ConsentDetails from "./ConsentDetails";
import { useState } from "react";
import sizeFontCustom from "../Discovery/fontSize.styles";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ConsentsDetailsCardLSP from "./ConsentsDetailsCardLSP";
function ConsentCardLsp(props) {
  const {  consentDetails, customDetails,  isMultiConsent, selectedLenders,  handleCardClick } = props;
  const isActiveFlag = selectedLenders.includes(consentDetails?.ConsentHandle)?true:false
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showDetails, setShowDetails] = useState(false)
  const handleDetails = () => {
    setShowDetails(true);
  }
  const originalConsentTypesArray = consentDetails.consentTypes;
  const formattedConsentTypesArray = originalConsentTypesArray?.map(
    (item) => item.charAt(0) + item.slice(1).toLowerCase()
  );
  const formattedConsentTypesString = formattedConsentTypesArray?.join(", ");

  const originalUnit = consentDetails?.Frequency?.unit;
  const formattedUnit = originalUnit?.charAt(0) + originalUnit?.slice(1).toLowerCase();

  const isLsp = global.institutionType === "LSP"?true:false;
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          m: mobileScreen ? "0 0.25rem 0 0" : "0.25rem 0rem 0.25rem 0",
          flex: 1,
          height: "100%",
          // borderLeft: mobileScreen && id === 1 ? `15px solid ${theme.palette.secondary.main}` : null,
          maxWidth: isLsp?"100%": mobileScreen ? "90vw" : "auto"
        }}>
        <CardActionArea
          onClick={() => handleCardClick(consentDetails?.ConsentHandle)}
          sx={{
            boxShadow: isActiveFlag ? 5 : mobileScreen ? null : null,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: mobileScreen ? "1rem 0.625rem 0 0.625rem" : "1.25rem 2.75rem 0 1.5rem",
            borderLeft:
              !mobileScreen
                ? isActiveFlag
                  ? `5px solid ${theme.palette.primary.main}`
                  : `5px solid ${theme.palette.secondary.main}`
                : `5px solid ${theme.palette.secondary.main}`,
            borderTop:
              mobileScreen
                ? isActiveFlag
                  ? `5px solid ${theme.palette.primary.main}`
                  : `5px solid ${theme.palette.secondary.main}`
                : `5px solid ${theme.palette.secondary.main}`,
            backgroundColor: isActiveFlag ?  "white": theme.palette.primary.white,
            borderBottom: mobileScreen ? "thin solid rgba(0, 0, 0, 0.12)" : 'none',
            gap: mobileScreen ? "0.5rem" : "1rem",
            width: "100%",
            borderBottomRightRadius: mobileScreen ? 0 : "inherit",
            borderBottomLeftRadius: mobileScreen ? 0 : "inherit",
          }}>
          <Stack
            justifyContent={"space-between"}
            gap={mobileScreen ? "1rem" : "1.625rem"}
            width={"100%"}
          >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box display={"flex"} flexDirection={"row"} height={isMultiConsent && mobileScreen ? "64px" : "auto"} alignItems={"center"}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar
                  src={consentDetails?.entityInfoDetails?.entityLogoUri ? consentDetails?.entityInfoDetails?.entityLogoUri: <AccountBalanceIcon/>}
                  sx={{
                    "& .MuiAvatar-img": {
                    objectFit: "contain",}, 
                    mr: "0.678rem",
                    height:40, 
                    width:40
                  }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    pl: mobileScreen ? "0.25rem" : "0.5rem",
                  }}
                >
                  <Box sx={{ width: "100%", textAlign: "center", display: "flex", flexDirection: "row" }}>
                  <Typography sx={sizeFontCustom.subHeading}>
                      {consentDetails?.Purpose?.text}
                    </Typography>
                  </Box>
                  <Typography sx={{
                    ...sizeFontCustom.cardTitle,
                    maxWidth: mobileScreen ? "60vw" : "19vw",
                    fontWeight: 700,
                    fontFamily: customDetails?.fontFamily,
                    textAlign: "left",
                  }}>
                    {consentDetails.entityInfoDetails?.entityName}{/* {consentDetails?.Purpose?.text} */}
                  </Typography>
                </Box>
              </Box>
              <Checkbox
                    color={"primary"}
                    checked={isActiveFlag}        
                    key={Math.random()}
                    name={consentDetails?.ConsentHandle}
                    />
            </Box>
          </Stack>
          <Divider variant="light" flexItem />
          <Grid
            container
            justifyContent={"space-between"}
            gap={{ xs: "0.8rem", md: 1 }}
            sx={{ display: "flex", gap: "0.5rem", mb:"1rem"}}
          >
        <ConsentsDetailsCardLSP title="Data From Date">
          {dateFormat(consentDetails?.DataDateTimeRange?.from, false)}
        </ConsentsDetailsCardLSP>

        <ConsentsDetailsCardLSP title="Data To Date">
          {dateFormat(consentDetails?.DataDateTimeRange?.to, false)}
        </ConsentsDetailsCardLSP>
        <ConsentsDetailsCardLSP title="Frequency">
          {consentDetails?.fetchType === "ONETIME"
            ? "Information fetch one time"
            : `Information fetch ${consentDetails?.Frequency?.value} times a ${formattedUnit}`}
        </ConsentsDetailsCardLSP>
        <ConsentsDetailsCardLSP title="Account Information">
          {formattedConsentTypesString}
        </ConsentsDetailsCardLSP>
        {consentDetails?.mode === "STORE" ? (
          <ConsentsDetailsCardLSP title={"Data Store"}>
            Until {consentDetails?.DataLife?.value} {consentDetails?.DataLife?.unit}
          </ConsentsDetailsCardLSP>
        ) : null}
        {consentDetails?.mode === "VIEW" ? (
          <ConsentsDetailsCardLSP title={"Data View"}>
            Until {consentDetails?.DataLife?.value} {consentDetails?.DataLife?.unit}
          </ConsentsDetailsCardLSP>
        ) : null}
        <ConsentsDetailsCardLSP title="Expires On">
          {dateFormat(consentDetails?.expireTime, false)}
        </ConsentsDetailsCardLSP>
      </Grid>
          <Button
            variant="text"
            onClick={handleDetails}
            endIcon={<EastIcon />}
            sx={{
              ...sizeFontCustom.subHeading,
              fontWeight: 700,
              fontFamily: customDetails?.fontFamily,
              m: { xs: `-1rem 0rem ${isMultiConsent ? "1.5rem" : "0.5rem"} 2rem`, md: "-1.5rem 0rem 0.5rem 0.3rem" },
              textTransform: "none",
            }}>
            View More Details
          </Button>
        </CardActionArea>
      </Paper>
      <Drawer
        open={showDetails}
        setOpen={setShowDetails}
        medWidth={"30%"}
        closeBtnTop="0.35rem"
        anchor={mobileScreen ? "bottom" : "right"}
        height={mobileScreen ? "70vh" : "100vh"}
        customDetails={customDetails}
        showCloseBtn={mobileScreen ? false : true}
      >
        <ConsentDetails consent={consentDetails} />
      </Drawer>
    </>
  )
}

export default ConsentCardLsp;