import {
	Box,
	Button,
	Link,
	Switch,
	Typography,
	useMediaQuery,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import RequestedConsents from './paymentConsent/RequestedConsents';
import ConsentListContext from './Context/ConsentContext';
import { useTheme } from '@emotion/react';
import linkedAccountsSectionStyles from './Accounts/LinkedAccountsSection.styles';
import ConsentCardLsp from './paymentConsent/ConsentCardLSP';
import WhyShare from './Sections/WhyShare';
import ConsentCardLspMobile from './paymentConsent/ConsentCardLspMobile';

function LenderSelection(props) {
	const consentContext = useContext(ConsentListContext);
	const consentsAll = consentContext?.consentList
		? consentContext?.consentList
		: [];
	const [selectedLenders, setSelectedLenders] = useState(
		consentsAll.map((a) => {
			return a?.ConsentHandle;
		})
	);
	const theme = useTheme();
	const [selectAll, setSelectAll] = useState(true);
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const selectUnselectAcc = (consent) => {
		setSelectedLenders((prevLenders) => {
			if (prevLenders.includes(consent)) {
				const index = prevLenders.indexOf(consent);
				prevLenders.splice(index, 1);
			} else {
				prevLenders.push(consent);
			}
			consentContext.updateSelectedConsentList(
				consentsAll.filter((x) => prevLenders.includes(x.ConsentHandle))
			);
			if (prevLenders.length < consentsAll.length) {
				setSelectAll(false);
			} else {
				setSelectAll(true);
			}
			return [...prevLenders];
		});
	};

	const toggleSelectAll = () => {
		if (selectedLenders?.length < consentsAll.length) {
			setSelectedLenders(
				consentsAll.map((a) => {
					return a?.ConsentHandle;
				})
			);
			setSelectAll(true);
		} else {
			setSelectedLenders([]);
			setSelectAll(false);
		}
	};

	useEffect(() => {
		consentContext.updateActiveSlide(0);
		setSelectedLenders(
			consentContext.selectedConsents.map((a) => {
				return a?.ConsentHandle;
			})
		);
	}, []);

	const handleNext = () => {
		consentContext.updateSelectedConsentList(
			consentsAll.filter((x) => selectedLenders.includes(x.ConsentHandle))
		);
		props.history.push(`/payment-consent`);
	};
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: { xs: 'start', md: 'center' },
				flexDirection: { xs: 'column', sm: 'column', md: 'row' },
				minHeight: '100vh',
				alignItems: 'start',
			}}
		>
			<RequestedConsents
				customDetails={props.customDetails}
				display={'flex'}
				{...props}
			/>
			<Box
				sx={{
					padding: mobileScreen ? '1rem 0.5rem 5rem 0.5rem' : '5rem 1.875rem',
					backgroundColor: theme.palette.primary.light,
					gap: mobileScreen ? '1rem' : '1.5rem',
					flex: 3,
					...linkedAccountsSectionStyles.container,
				}}
			>
				<Box
					width={'100%'}
					sx={{
						margin: {
							xs: '0 0.5rem 0.25rem 0.5rem',
							md: '1rem 0 0 0',
						},
					}}
				>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: mobileScreen ? 'flex-start' : 'center',
							flexDirection: mobileScreen ? 'column-reverse' : 'row',
						}}
					>
						<Typography sx={linkedAccountsSectionStyles.title}>
							Select institutions to share data
						</Typography>
						{!mobileScreen && (
							<Typography
								sx={{
									fontSize: '0.875rem',
									color: theme.palette.black60,
									fontFamily: props.customDetails.fontFamily,
								}}
							>
								{`AA handle: ${global?.userId}`}
							</Typography>
						)}
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							mt: '0.75rem',
							pr: { xs: '0.5rem', md: 0 },
						}}
					>
						<Typography color={theme.palette.black60}>
							Some Text here to be added
						</Typography>
						<Link
							color={theme.palette.primary.main}
							onClick={() => {
								toggleSelectAll();
							}}
							sx={linkedAccountsSectionStyles.changeNumberLink}
						>
							Select All
							<Switch
								checked={selectAll}
								onChange={toggleSelectAll}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</Link>
					</Box>
				</Box>
				<Box
					width={'100%'}
					display={'flex'}
					flexDirection={'column'}
					gap={{ xs: '1rem' }}
					alignItems={mobileScreen ? 'center' : 'none'}
				>
					{consentsAll.map((consent, idx) => {
						return !mobileScreen ? (
							<ConsentCardLsp
								key={idx}
								customDetails={props.customDetails}
								consentDetails={consent}
								isMultiConsent={consentsAll?.length > 1}
								selectedLenders={selectedLenders}
								handleCardClick={selectUnselectAcc}
							/>
						) : (
							<ConsentCardLspMobile
								key={idx}
								customDetails={props.customDetails}
								consentDetails={consent}
								isMultiConsent={consentsAll?.length > 1}
								selectedLenders={selectedLenders}
								handleCardClick={selectUnselectAcc}
							/>
						);
					})}
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						width: '100%',
					}}
				>
					{mobileScreen ? <WhyShare {...props} /> : null}
					<Box
						sx={{
							mr: '-0.5rem',
							bottom: mobileScreen ? '0px' : '1.875rem',
							backgroundColor: mobileScreen ? 'white' : 'none',
							gap: mobileScreen ? '1rem' : '1.25rem',
							width: mobileScreen ? '100vw' : '15%',
							...linkedAccountsSectionStyles.buttonGroup,
						}}
					>
						<Button
							variant="contained"
							fullWidth={mobileScreen}
							disabled={selectedLenders.length === 0}
							onClick={handleNext}
							sx={{
								backgroundColor: props.customDetails.primaryColor,
								flex: 1,
								color: theme.palette.primary.white,
							}}
						>
							Select Accounts
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default LenderSelection;
