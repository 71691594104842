import { Box, Button, Grid, Link, List, alpha, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react";
import AuthApis from "../../hepler/authApis.service";
import { toast } from 'react-toastify';
import FipVerifiedHeading from "./FipVerifiedHeading";
import { useRef } from "react";
import styles from "./FipDiscoveredAccountsSection.styles";
import sizeFontCustom from "./fontSize.styles"
import FipDiscoveredAccounts from "./FipDiscoveredAccounts";
import VerifyOtp from "./VerifyOtp";
import Drawer from "../Drawer/Drawer";
function FipDiscoveredAccountsSection(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const authApis = new AuthApis();

  const fipId = props.accountsSingleFip.fipId;
  const fipAccountsAll = props.accountsSingleFip.discoveredAccounts.flat(1)
  const phoneNumber = props.accountsSingleFip.phoneNumber
  const accountsNotLinked = fipAccountsAll
    .filter((acc) => !props.accountState.linkedAccounts.includes(acc.accRefNumber));
  const accountsLinked = fipAccountsAll
    .filter((acc) => props.accountState.linkedAccounts.includes(acc.accRefNumber));
  const [selectedAccounts, setSelectedAccounts] = useState(accountsNotLinked);
  // eslint-disable-next-line no-unused-vars
  const [checkedItems, setCheckedItems] = useState({});
  // const [showChangeNumber, setShowChangeNumber] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [verifyState, setVerifyState] = useState({
    isProceed: true,
    isResend: true,
    isDisable: false,
    OTP: null,
    linkingRefNO: "",
    time: 60,
    linkedStatus: []
  })

  // const handleChangeNumber = () => {
  //   props.setChangingMobileNumber(true)
  //   setShowChangeNumber(true)
  // }
  const id = useRef("")
  const initiate = () => {
    setVerifyState((prevState) => {
      const newTime = prevState.time - 1
      if (newTime === 0) {
        clearInterval(id.current);
        return { ...prevState, time: 60, isResend: true }
      }
      return { ...prevState, time: newTime }
    });
  };
  const setResend = () => {
    clearInterval(id.current);
    id.current = setInterval(initiate, 1000);
  };
  const accountLinking = () => {
    setVerifyState({ ...verifyState, isDisable: true, isResend: false });
    authApis
      .accountLinking(fipId, selectedAccounts)
      .then((res) => {
        setVerifyState({ ...verifyState, isDisable: false });
        if (!res) {
          toast.error('Something went wrong,Please try again');
        }
        if (res.status === 'SUCCESS') {
          setVerifyState({
            ...verifyState,
            isProceed: false,
            isResend: false,
            OTP: '',
            linkingRefNO: res.RefNumber
          });
          setResend()
        } else {
          setVerifyState({ ...verifyState, isResend: true });
          toast.error(res.message);

          setTimeout(() => {
            props.history.push({
              pathname: `/consent-status`,
              state: {
                statusCode: 403,
              }
            });
          }, 3000)
        }
      });
  };

  const accountConfirmLinking = () => {
    setVerifyState({ isDisable: true, isResend: false });
    authApis
      .accountConfirmLinking(verifyState.linkingRefNO, verifyState.OTP)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          const linkedAccounts = res?.AccLinkDetails.map((acc)=>acc.accRefNumber)
          setVerifyState({
            ...verifyState,
            isDisable: false,
            isResend: true,
            linkedStatus: linkedAccounts,
          });
          toast.success(res.message);
        } else {
          setVerifyState({
            ...verifyState,
            OTP: '',
          });
          toast.error(res?.message);
          if (res.status === 'FAILURE') {
            postMessage('session', 'FAILURE');
          }
        }
      });
  };

  const handleVerify = () => {
    accountLinking()
  }
  const handleCheckedState = (event, account) => {
    selectUnselectAcc(account);
    const { name, checked } = event.target;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: checked,
    }));
  }
  const selectUnselectAcc = (account) => {
    let data = selectedAccounts;
    if (selectedAccounts.includes(account)) {
      let index = data.indexOf(account);
      data.splice(index, 1);
    } else {
      data.push(account);
    }
    setSelectedAccounts(data);
  };

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };
  return (
    <Grid item
      xs={12}
      sm={8}
      md={8}
      lg={5.5}
    >
      <List sx={{
        background: theme.palette.primary.white,
        justifyContent: "start",
        alignItems: "start",
        borderRadius: "1rem",
        padding: "0.5rem"
      }}>
        <FipVerifiedHeading
          allLinked={accountsNotLinked.length === 0 && accountsLinked.length > 0}
          isVerified={verifyState.linkedStatus}
          fipId={fipId}
          phoneNumber={phoneNumber}
          areAccountsDiscovered={fipAccountsAll.length > 0}
        />
        {
          fipAccountsAll.length === 0 ?
            <>
            </>
            :
            (<Box
              sx={{
                ...styles.discoveredAccounts,
                maxHeight: showAll ? "auto" : "10.5rem",
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.5),
                  borderRadius: "10px",
                }
              }}
            >
              {accountsNotLinked.length > 0 ?
                accountsNotLinked.map((account) => {
                  return (
                    <FipDiscoveredAccounts
                      account={account}
                      fipId={fipId}
                      handleCheckedState={handleCheckedState}
                      selectedAccounts={selectedAccounts}
                      isVerified={verifyState.linkedStatus}
                      isLinked={false}
                    />
                  )
                })
                : accountsLinked.map((account) => {
                  return (
                    <FipDiscoveredAccounts
                      account={account}
                      fipId={fipId}
                      isVerified={verifyState.linkedStatus}
                      handleCheckedState={handleCheckedState}
                      selectedAccounts={selectedAccounts}
                      isLinked={true}
                    />
                  )
                })
              }

            </Box>)}
        <Box display={(accountsNotLinked.length - 2 > 0) ? 'block' : "none"} textAlign={'start'}>
          <Link
            display="block"
            underline="none"
            sx={{ cursor: "pointer", ...sizeFontCustom.subHeading, mt: "-0.5rem", ml: "1rem" }}
            onClick={toggleShowAll}
          >
            {showAll ? "Show Less" : `+ ${accountsNotLinked.length - 2} more`}
          </Link>
        </Box>
        <List disablePadding sx={{ mt: "0.5rem" }}>
          {verifyState.linkedStatus?.length===0 && (accountsNotLinked.length > 0) ?
            (verifyState.isProceed ?
              <Box mx={"1rem"}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleVerify}
                  sx={{
                    textTransform: "capitalize",
                    ...sizeFontCustom.subHeading
                  }}
                >
                  Link account
                </Button>
              </Box> :
              (
                !mobileScreen?
                <VerifyOtp
                  phoneNumber = {phoneNumber} 
                  setVerifyState= {setVerifyState} 
                  verifyState = {verifyState} 
                  handleVerify = {handleVerify}
                  accountConfirmLinking = {accountConfirmLinking}
                />:
                <Drawer
                  open={!verifyState.isProceed}
                  setOpen={()=>
                    setVerifyState({
                      ...verifyState,
                      isProceed : true
                    })}
                  medWidth={"30%"}
                  closeBtnTop="0.35rem"
                  anchor={ "bottom" }
                  height={"30vh" }
                  customDetails={props.customDetails}
                  showCloseBtn={mobileScreen ? false : true}
                >
                  <VerifyOtp
                  phoneNumber = {phoneNumber} 
                  setVerifyState= {setVerifyState} 
                  verifyState = {verifyState} 
                  handleVerify = {handleVerify}
                  accountConfirmLinking = {accountConfirmLinking}
                /> 
              </Drawer>
                )
            ) : null}
        </List>

      </List>
    </Grid>
  )
}

export default FipDiscoveredAccountsSection;
