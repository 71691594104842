const sizeFontCustom = {
	field: {
		fontSize: {
			xs: '1rem',
			md: '1.25rem',
		},
	},
	consentField: {
		fontSize: '0.75rem',
	},
	//Changed
	subHeading: {
		fontSize: { xs: '0.75rem' },
	},
	requestedText: {
		fontSize: { xs: '0.75rem', sm: '0.75rem' },
	},
	heading: {
		fontSize: {
			xs: '1rem',
			md: '0.875rem',
		},
	},
	linkAccountsText: {
		fontSize: {
			xs: '0.875rem',
			md: '0.875rem',
		},
	},
	cardText: {
		fontSize: {
			xs: '0.875rem',
			md: '0.75rem',
		},
	},
	cardTitle: {
		fontSize: '0.875rem',
	},
	title: {
		fontSize: {
			xs: '1.125rem',
			md: '1.25rem',
		},
	},
	titleMain: {
		fontSize: {
			xs: '1rem',
			md: '1.25rem',
		},
	},
	dialogTitle: {
		fontSize: '1.125rem',
	},
	toolTipText: {
		fontSize: {
			xs: '0.75rem',
			md: '0.65rem',
		},
	},
};
export default sizeFontCustom;
