const lenderStyles = {
  avatar: {
    mr: 1,
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
    // "& .MuiAvatar-img"
    "& img": {
      objectFit: "contain",
      width: { xs: 40, md: 40 },
      height: { xs: 40, md: 40 },
    },
  },
  avatarGrp: {
    "& .MuiAvatar-root": {
      width: { xs: 40, md: 50 },
      height: { xs: 40, md: 50 },
      fontSize: { xs: "1.5rem", md: "1rem" },
    },
    justifyContent: "start",
  },
};
export default lenderStyles;
