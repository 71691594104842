import { Avatar, Box, Button, CardActionArea, Checkbox, Divider, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import EastIcon from '@mui/icons-material/East';
import ConsentDetailSingle from "./ConsentDetailSingle";
import { capitalize, dateFormat } from "../../hepler/functions";
import Drawer from "../Drawer/Drawer";
import ConsentDetails from "./ConsentDetails";
import { useState } from "react";
import sizeFontCustom from "../Discovery/fontSize.styles";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function ConsentCardLspMobile(props) {
  const {  consentDetails, customDetails, selectedLenders, isMultiConsent, handleCardClick } = props;
  const isActiveFlag = selectedLenders.includes(consentDetails?.ConsentHandle)?true:false
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showDetails, setShowDetails] = useState(false)

  const handleDetails = () => {
    setShowDetails(true);
  }

  const acInfoTransform = (el, infoType, i) => {
    return (
      <span key={i}>
        {capitalize(el).split('_').join(' ')}
        {infoType?.length - 1 === i ? "" : ", "}
      </span>
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          m: mobileScreen ? "0 0.25rem 0 0" : "0.25rem 0rem 0.25rem 0",
          flex: 1,
          height: "100%",
          // borderLeft: mobileScreen && id === 1 ? `15px solid ${theme.palette.secondary.main}` : null,
          maxWidth: mobileScreen ? "90vw" : "auto"
        }}>
        <CardActionArea
          onClick={() => handleCardClick(consentDetails?.ConsentHandle)}
          sx={{
            boxShadow: isActiveFlag ? 5 : mobileScreen ? null : null,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: mobileScreen ? "1rem 0.625rem 0 0.625rem" : "1.25rem 2.75rem 0 1.5rem",
            borderLeft:
              !mobileScreen
                ? isActiveFlag
                  ? `5px solid ${theme.palette.primary.main}`
                  : `5px solid ${theme.palette.secondary.main}`
                : `5px solid ${theme.palette.secondary.main}`,
            borderTop:
              mobileScreen
                ? isActiveFlag
                  ? `5px solid ${theme.palette.primary.main}`
                  : `5px solid ${theme.palette.secondary.main}`
                : `5px solid ${theme.palette.secondary.main}`,
            backgroundColor: isActiveFlag ? theme.palette.primary.light : "white",
            borderBottom: mobileScreen ? "thin solid rgba(0, 0, 0, 0.12)" : 'none',
            gap: mobileScreen ? "0.5rem" : "1rem",
            width: "100%",
            borderBottomRightRadius: mobileScreen ? 0 : "inherit",
            borderBottomLeftRadius: mobileScreen ? 0 : "inherit",
          }}>
          <Stack
            justifyContent={"space-between"}
            gap={mobileScreen ? "1rem" : "1.625rem"}
            flexDirection={"row"}
            width={"100%"}
          >
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar
                  src={consentDetails?.entityInfoDetails?.entityLogoUri ? consentDetails?.entityInfoDetails?.entityLogoUri: <AccountBalanceIcon/>}
                  sx={{
                    "& .MuiAvatar-img": {
                    objectFit: "contain",}
                    ,mr: "0.678rem",}}
                  />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  pl: mobileScreen ? "0.25rem" : "0.5rem",
                }}
              >
                <Typography sx={{
                    ...sizeFontCustom.cardTitle,
                    maxWidth: mobileScreen ? "60vw" : "19vw",
                    fontWeight: 700,
                    fontFamily: customDetails?.fontFamily,
                    textAlign: "left",
                  }}>
                    {consentDetails.entityInfoDetails?.entityName}{/* {consentDetails?.Purpose?.text} */}
                  </Typography>
                <Box sx={{ width: "100%", textAlign: "center", display: "flex", flexDirection: "row" }}>
                  <Typography sx={sizeFontCustom.subHeading}>
                      {consentDetails?.Purpose?.text}
                    </Typography>
                  </Box>

              </Box>
            </Box>
              <Checkbox
                    color={"primary"}
                    checked={isActiveFlag}        
                    key={Math.random()}
                    // onChange={(event) => handleCheckedState(event, account)}
                    name={consentDetails?.ConsentHandle}
                    />
          </Stack>
          <Divider variant="light" flexItem />
          <Stack component="ul" sx={{ pl: "1.5rem", mt: "0rem" }} spacing="0.2rem">
            <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem", paddingBottom: "0rem" }}>
              <ConsentDetailSingle
                customDetails={customDetails}
                title={"Data fetch "}
                flexDirection="row"
                date={true}
                fromDate={dateFormat(consentDetails?.DataDateTimeRange?.from, false)}
                toDate={dateFormat(consentDetails?.DataDateTimeRange?.to, false)}
              />
            </li>
            <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem" }}>
              <ConsentDetailSingle
                customDetails={customDetails}
                title=
                {consentDetails?.fetchType === 'ONETIME' ?
                  'Information will be fetched one time' :
                  `Information will be fetched up to ${consentDetails?.Frequency?.value} 
                                      times a ${capitalize(consentDetails?.Frequency?.unit)}`}
                flexDirection="row"
              />
            </li>
            <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem" }}>
              <ConsentDetailSingle
                customDetails={customDetails}
                title={"Data " + capitalize(consentDetails?.mode)}
                description={"Until " + capitalize(consentDetails?.DataLife?.value + " " + consentDetails?.DataLife?.unit)}
                flexDirection="row"
              />
            </li>
            <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem" }}>
              <ConsentDetailSingle
                customDetails={customDetails}
                title={"Account information shared"}
                description={consentDetails?.consentTypes.map((el, i) => acInfoTransform(el, consentDetails?.consentTypes, i))}
              />
            </li>
          </Stack>
          <Button
            variant="text"
            onClick={handleDetails}
            endIcon={<EastIcon />}
            sx={{
              ...sizeFontCustom.subHeading,
              fontWeight: 700,
              fontFamily: customDetails?.fontFamily,
              m: { xs: `-1rem 0rem ${isMultiConsent ? "1.5rem" : "0.5rem"} 2rem`, md: "-1.5rem 0rem 0.5rem 0.3rem" },
              textTransform: "none",
            }}>
            View More Details
          </Button>
        </CardActionArea>
      </Paper>
      <Drawer
        open={showDetails}
        setOpen={setShowDetails}
        medWidth={"30%"}
        closeBtnTop="0.35rem"
        anchor={mobileScreen ? "bottom" : "right"}
        height={mobileScreen ? "70vh" : "100vh"}
        customDetails={customDetails}
        showCloseBtn={mobileScreen ? false : true}
      >
        <ConsentDetails consent={consentDetails} />
      </Drawer>
    </>
  )
}

export default ConsentCardLspMobile;