import {
  Avatar,
  Box,
  Divider,
  Paper,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  useMediaQuery,
  AccordionDetails,
  ButtonBase
} from '@mui/material'
import React from 'react'
import stylesLogo from './Account.styles'
import AddNewButton from '../Buttons/AddNewButton';
import { useState } from 'react';
import AccountCheckboxContainer from './AccountCheckboxContainer';
import linkedAccountsFIStyles from './LinkedAccountsFI.styles';
import sizeFontCustom from "../Discovery/fontSize.styles"

function LinkedAccountsFI(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const redirect = () => {
    global.fi = global.FIGroups?.[name]
    props.history.push(`/home/?number=${global.mobileNumber}&isAddAccount=${true}`, { fiTypes: global.FIGroups[name] },)
  }
  const { linkedAccounts, name, fIGroupsWithZeroAccts } = props;
  const handleAddAccount = () => {
    redirect()
    return
    // switch(name){
    //   case "Insurance":
    //     setOpenDOB(true)
    //     break;
    //   case "Mutual Funds":
    //   case "Equities":
    //   case "GSTIN":
    //     setOpenPAN(true)
    //     break;
    //   default:
    //     redirect()
    // }
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const requiredAccounts = linkedAccounts?.filter((account) => global.FIGroups?.[name]?.includes(account?.FIType))
  const accountsLinkedFlag = requiredAccounts?.length > 0;
  const isBankAccountsSection = name === "Bank Accounts";
  const accordionAccountsDetails =
    (!isBankAccountsSection)
      ? mobileScreen
        ? 1
        : 3
      : requiredAccounts?.length
        ? requiredAccounts?.length
        : fIGroupsWithZeroAccts?.length;
  return (
    <Paper sx={stylesLogo.paper}>
      <Box>
        <Box sx={linkedAccountsFIStyles.headingContainer}>
          <Typography noWrap variant="h6" sx={linkedAccountsFIStyles.name}>
            {name}
            {accountsLinkedFlag && (
              <Avatar
                sx={{
                  ...linkedAccountsFIStyles.count,
                  bgcolor: `${theme.palette.info.light}`,
                }}
              >
                {requiredAccounts.length}
              </Avatar>
            )}
          </Typography>
          {accountsLinkedFlag &&
            <>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ display: { xs: "none", md: "flex" } }}
              />
              <AddNewButton handleAddAccount={handleAddAccount} />
            </>
          }
        </Box>
        <>
          {
            !isBankAccountsSection && accountsLinkedFlag ?
              <Accordion
                elevation={0}
                expanded={isExpanded}
                sx={linkedAccountsFIStyles.accordian}>
                <AccordionSummary
                  onClick={() => { }}
                  sx={linkedAccountsFIStyles.accordianSummary}
                  expandIcon={!isExpanded && requiredAccounts.length > accordionAccountsDetails &&
                    <ButtonBase
                      sx={{ ...sizeFontCustom.heading, color: theme.palette.primary.main }}
                      onClick={() => setIsExpanded(true)}
                      color={
                        "primary"
                      }>
                      {`+ ${requiredAccounts.length - accordionAccountsDetails} more`}
                    </ButtonBase>}
                  aria-controls="linkedAccountsPanel-content"
                  id="linkedAccountsPanel-header"
                >
                  <AccountCheckboxContainer
                    requiredAccounts={requiredAccounts}
                    isZero={false}
                    start={0}
                    end={accordionAccountsDetails}
                    {...props}
                  />
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, m: "0 0 0 0" }}>
                  <AccountCheckboxContainer
                    requiredAccounts={requiredAccounts}
                    isZero={false}
                    start={accordionAccountsDetails}
                    end={requiredAccounts.length}
                    {...props}
                  />
                  <Box textAlign={"start"} mt={"0.5rem"}>
                    <ButtonBase
                      sx={{ ...sizeFontCustom.heading, color: theme.palette.primary.main }}
                      onClick={() => setIsExpanded(false)}
                    >
                      {"Show less"}
                    </ButtonBase>
                  </Box>
                </AccordionDetails>
              </Accordion>
              :
              <AccountCheckboxContainer requiredAccounts={requiredAccounts} isZero={false} start={0} end={accordionAccountsDetails} {...props} />
          }
          {/* For fiTypes having 0 linked accounts */}
          {!accountsLinkedFlag && fIGroupsWithZeroAccts?.length && (
            <Accordion
              elevation={0}
              expanded={isExpanded}
              sx={linkedAccountsFIStyles.accordian}>
              <AccordionSummary
                onClick={() => { }}
                sx={linkedAccountsFIStyles.accordianSummary}
                expandIcon={!isExpanded && fIGroupsWithZeroAccts?.length > accordionAccountsDetails &&
                  <ButtonBase
                    sx={{ ...sizeFontCustom.heading, color: theme.palette.primary.main }}
                    onClick={() => setIsExpanded(true)}
                    color={
                      "primary"
                    }>
                    {`+ ${fIGroupsWithZeroAccts?.length - accordionAccountsDetails} more`}
                  </ButtonBase>}
                aria-controls="noAccountsLinkedPanel-content"
                id="noAccountsLinkedPanel-header"
              >
                <AccountCheckboxContainer requiredAccounts={fIGroupsWithZeroAccts} isZero={true} start={0} end={accordionAccountsDetails} {...props} />
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, m: "0 0 0 0" }}>
                <AccountCheckboxContainer requiredAccounts={fIGroupsWithZeroAccts} isZero={true} start={accordionAccountsDetails} end={fIGroupsWithZeroAccts?.length} {...props} />
                <Box textAlign={"start"} mt={"0.5rem"}>
                  <ButtonBase
                    sx={{ ...sizeFontCustom.heading, color: theme.palette.primary.main }}
                    onClick={() => setIsExpanded(false)}
                  >
                    {"Show less"}
                  </ButtonBase>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      </Box>
    </Paper>
  )
}

export default LinkedAccountsFI