export default class AuthApis {

    async userLogin() {
        try {
            const res = await window.finvuClient.loginEncrypt()
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async verifyOtp(otp) {
        try {
            const res = await window.finvuClient.verifyOTPCommon(otp)
            console.log("data  - ", res)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async getUserDetails(otp) {
        try {
            const res = await window.finvuClient.userDetails()
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async getUserInfo() {
      try {
          const res = await window.finvuClient.userInfo()
          if (res) {
              return res;
          }
      } catch (e) {

      }
  }
    async getLinkedAccounts() {
        try {
            const res = await window.finvuClient.userLinkedAccounts()
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }

    async accountLinking(fipid, account) {
        try {
            const res = await window.finvuClient.accountLinking(fipid, account)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async accountConfirmLinking(accLinkRef, otp) {
        try {
            const res = await window.finvuClient.accountConfirmLinking(accLinkRef, otp)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async consentRequestDetails() {
        try {
            const res = await window.finvuClient.consentRequestDetailsEncList()
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async consentRequestDetailsSingle() {
      try {
          const res = await window.finvuClient.consentRequestDetailsEnc()
          if (res) {
              return res;
          }
      } catch (e) {

      }
  }
    async consentApproveRequest(data, status, consentHandle, fiu) {
        if (consentHandle && fiu) {
            try {
                const res = await window.finvuClient.consentApproveRequestAll(data, status, consentHandle, fiu)
                if (res) {
                    return res;
                }
            } catch (e) {

            }
        } else {
            try {
                const res = await window.finvuClient.consentApproveRequest(data, status)
                if (res) {
                    return res;
                }
            } catch (e) {

            }
        }

    }
    async mobileVerifyReq(number) {
        console.log("number - ", number)
        try {
            const res = await window.finvuClient.mobileVerificationRequest(number)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async varifyMobile(number, otp) {
        try {
            const res = await window.finvuClient.mobileVerificationVerfiyRequest(number, otp)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async getFiuInfo(ecreq, reqdate, fi, requestorType) {
        if (requestorType) {
            console.log("requestorType - ", requestorType)
            try {
                const res = await window.finvuClient.fiuInfo(ecreq, reqdate, fi, requestorType)
                if (res) {
                    return res;
                }
            } catch (e) {

            }
        } else {
            try {
                const res = await window.finvuClient.fiuInfo(ecreq, reqdate, fi)
                if (res) {
                    return res;
                }
            } catch (e) {

            }
        }

    }
    async popularFipList() {
        try {
            const res = await window.finvuClient.popularFipList()
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async fiuSdkConfig(entityId) {
        try {
            const res = await window.finvuClient.entitySdkConfig(entityId)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async entityInfo(entityId) {
        try {
            const res = await window.finvuClient.entityInfo(entityId, 'FIU')
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async logout(userId) {
        try {
            const res = await window.finvuClient.logout(userId)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async encryptRequest(code) {
        try {
            const res = await window.finvuClient.encryptRequest(code)
            if (res) {
                return res;
            }
        } catch (e) {

        }
    }
    async fipDetails(fipId) {
        try {
            const res = await window.finvuClient.fipDetails(fipId);
            if (res) {
                return res;
            }
        } catch (e) {
            /* empty */
        }
    }

}