import { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'react-toastify';
import { postMessage } from '../utils/common.utils';
import {
	Avatar,
	Box,
	Button,
	Icon,
	Link,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import LoginRight from './Login/LoginRight';
// import Footer from './Login/Footer';
import SetTheme from './common/SetTheme';

import DialogBox from './Dialog Box/Dialog Box';
import LoginRightContent from './Login/LoginRightContent';
import loginLeftStyles from './Login/LoginLeft.styles';
import Expired from './Expired';
import loginStyles from './Login/Login.styles';
import ConsentListContext from './Context/ConsentContext';
import finvuLogo from '../assets/finvuLogo.png';
import LockIcon from '@mui/icons-material/Lock';
import { getFiTypesHash } from '../hepler/functions';

export default class Login extends Component {
	static contextType = ConsentListContext;
	constructor(props) {
		super(props);
		const queryParams = new URLSearchParams(window.location.search);
		this.ecreq = queryParams.get('ecreq');
		this.reqdate = queryParams.get('reqdate');
		this.fi = queryParams.get('fi') ? queryParams.get('fi') : null;
		this.fip = queryParams.get('fip')
			? window.atob(queryParams.get('fip'))
			: null;
		this.requestorType = queryParams.get('requestorType')
			? queryParams.get('requestorType')
			: null;
		global.requestorType = queryParams.get('requestorType')
			? queryParams.get('requestorType')
			: null;
		global.isAddAccount = queryParams.get('isAddAccount') ? true : false;
		this.state = {
			time: 60,
			OTP: '',
			isResend: true,
			isOpenSheet: false,
			isDisable: false,
			disableType: '',
			theme: undefined,
			// termsAndConditions: true,
			mobileNo: '',
			consentRequestList: [],
			showDetails: false,
			error: '',
			isExpired: false,
			consentList: [],
		};
		this.number = null;
		this.authApis = new AuthApis();
	}
	componentDidMount() {
		if (!this.props?.location?.state?.fromTerm) {
			this.getFiuInfo();
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	initiate = () => {
		// if (this.state.time !== 0) {
		this.setState((prevState, prevProps) => ({
			time: prevState.time - 1,
		}));
		if (this.state.time === 0) {
			clearInterval(this.id);
			this.setState({ time: 60, isResend: true });
		}
		// }
	};
	getFiuInfo = () => {
		this.props.updateInitialLoader(true);
		this.authApis
			.getFiuInfo(this.ecreq, this.reqdate, this.fi, this.requestorType)
			.then((res) => {
				if (res?.payload?.status === 'SUCCESS') {
					let entityId = res.payload.entityInfo.entityId;
					let no = res.payload.userIdOrMobileNo;
					this.setState({ mobileNo: no });
					global.institutionType = res.payload.institutionType;
					global.lspFips = res.payload.fips;
					global.entityName = res.payload?.entityInfo?.entityName;
					if (entityId) {
						// this.sendOtp()
						this.getConfigInfo(entityId);
					} else {
						this.props.updateInitialLoader(false);
						// this.props.history.push(`/error`)
						toast.error(res?.message);
						if (res.status === 'FAILURE') {
							postMessage('session', 'FAILURE');
						} else if (res.status === 'ERROR') {
							postMessage('session', 'ERROR');
						}
					}
				} else {
					toast.error(res.message);
					this.setState({ isExpired: true });
					this.props.updateInitialLoader(false);
				}
			});
	};
	getConfigInfo = (entityId) => {
		this.authApis.fiuSdkConfig(entityId).then((res) => {
			this.sendOtp();
			if (res?.status === 'SUCCESS') {
				if (res?.entityConfig?.themeConfig) {
					let entityConfig = res?.entityConfig?.themeConfig;
					entityConfig.excludeFIP = res?.entityConfig.excludeFIP
						? res?.entityConfig.excludeFIP
						: [];
					entityConfig.sdkOptions = res?.entityConfig?.sdkOptions
						? res?.entityConfig.sdkOptions
						: {};
					this.props.updateEntityID(entityConfig);
				}
				global.sdkOptions = res?.entityConfig?.sdkOptions;
				global.isRedirect = res?.entityConfig?.sdkOptions?.redirect;
				global.allowAutoDiscovery =
					res?.entityConfig?.sdkOptions?.allowAutoDiscovery;
				global.autoDiscoveryFips =
					res?.entityConfig?.sdkOptions?.autoDiscoveryFips;
				global.allowMultipleFipDiscovery =
					res?.entityConfig?.sdkOptions?.allowMultipleFipDiscovery;
				if (res?.entityConfig?.sdkOptions?.allowAutoDiscovery === 'true') {
					// global.isAddAccount=true
				}
			} else {
				this.props.updateInitialLoader(false);
			}
		});
	};
	// Send OTP
	sendOtp = () => {
		console.log(this.context, 'context');

		this.getOtp();
		this.setState({ isDisable: true, disableType: 'Resend' });
		this.authApis.userLogin().then((res) => {
			this.setState({ isDisable: false });
			this.props.updateInitialLoader(false);
			if (res.status === 'SEND') {
				if ('OTPCredential' in window) {
					window.addEventListener('DOMContentLoaded', (e) => {
						const ac = new AbortController();
						navigator.credentials
							.get({
								otp: { transport: ['sms'] },
								signal: ac.signal,
							})
							.then((otp) => {
								this.setState({ OTP: otp });
							})
							.catch((err) => {
								console.log(err);
							});
					});
				} else {
					console.log('WebOTP not supported!.');
				}
				//
			} else {
				toast.error(res.message);
				// this.props.history.push(`/error`)
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	verifyOtp = (e) => {
		e.preventDefault();
		this.setState({ isDisable: true, disableType: 'Verify' });
		this.authApis.verifyOtp(this.state.OTP).then((res) => {
			if (res?.status && res?.status === 'SUCCESS') {
				this.populerFips();
			} else {
				toast.error(res?.message);
				// this.setState({ time: 60, isResend: true });
				this.setState({ isDisable: false });
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	getUserdetails = () => {
		this.authApis.getUserDetails().then((res) => {
			if (res?.status && res?.status === 'ACCEPT') {
				console.log(res, 'this.getUserdetails');
				global.mobileNumber = res.UserInfo.mobileNo;
				global.userId = res.UserInfo.userId;
				global.pan = res?.UserInfo?.panProvided;
				global.dob = res?.UserInfo?.dobProvided;
				global.mobileNumberArray.push(res.UserInfo.mobileNo);
				this.setState({ mobileNo: res.UserInfo.mobileNo });
			} else {
				toast.error(res?.message);
				this.setState({ isDisable: false });
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	/*otp toggle*/
	getOtp = () => {
		this.setState({ isResend: false, OTP: '' });
		this.id = setInterval(this.initiate, 1000);
	};
	checkOtpValid = () => {
		if (this.state.OTP?.length === 6 || this.state.OTP?.length === 8) {
			return false;
		}
		return true;
	};
	populerFips = () => {
		this.authApis.popularFipList().then(async (res) => {
			this.setState({ isDisable: true });
			if (res?.status === 'SUCCESS') {
				global.populerFips = res?.poularSearch;
				let objectFormatFip = {};
				for (let i = 0; i < res?.poularSearch.length; i++) {
					objectFormatFip[res?.poularSearch[i].fipId] = res?.poularSearch[i];
				}
				global.objectFormatFip = objectFormatFip;
				await this.consentRequestDetails();
			} else {
				toast.error(res?.message);
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};

	consentRequestDetails = async () => {
		this.authApis.consentRequestDetails().then(async (res) => {
			this.props.updateLoader(false);
			if (res.status === 'SUCCESS') {
				await this.authApis.consentRequestDetailsSingle();
				this.getUserdetails();
				const validConsents = res.consentRequestList.filter(
					(el) => el.status === 'SUCCESS'
				);
				if (global.institutionType === 'LSP') {
					await this.getEntityInfo(validConsents);
				} else {
					const groupedConsents = {};
					for (const consent of validConsents) {
						const consentHash = getFiTypesHash(consent?.fiTypes);
						if (Object.keys(groupedConsents).includes(consentHash)) {
							groupedConsents[consentHash].add(consent);
						} else {
							groupedConsents[consentHash] = new Set().add(consent);
						}
					}
					this.context.updateCommonConsentFiTypesGroup(groupedConsents);
					this.context.updateConsentList(validConsents);
				}
				this.setState({ consentList: validConsents });
				global.consentListLength = validConsents?.length;
				global.consent =
					this.state.consentList?.length > 0
						? this.state.consentList[0]
						: validConsents[0];
				if (this.fip || global.allowAutoDiscovery === 'true') {
					let fips = '';
					if (this.fip) {
						if (
							global.autoDiscoveryFips &&
							global.allowAutoDiscovery === 'true'
						) {
							fips = !global.autoDiscoveryFips.includes(this.fip)
								? global.autoDiscoveryFips + ',' + this.fip
								: global.autoDiscoveryFips;
						} else {
							fips = this.fip;
						}
					} else {
						fips = global.autoDiscoveryFips;
					}
					this.props.history.push({
						pathname: `/accounts/${this.state.mobileNo.split('@')[0]}/${fips}`,
						state: { data: res.consentRequestList },
					});
				} else {
					this.props.history.push({
						pathname: `/accounts/${this.state.mobileNo}`,
						state: { data: res.consentRequestList },
					});
				}
			} else {
				toast.error(res.message);
				// this.props.history.push(`/error`)
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
				setTimeout(() => {
					this.props.history.push({
						pathname: `/consent-status`,
						state: {
							statusCode: 403,
						},
					});
				}, 3000);
			}
		});
	};
	getEntityInfo = async (consentRequestList) => {
		for (let i = 0; i < consentRequestList?.length; i++) {
			await this.authApis
				.entityInfo(consentRequestList[i].FIU.id)
				.then((result) => {
					console.log('entity Details - ', result);
					if (result.status === 'SUCCESS') {
						consentRequestList[i].entityInfoDetails = result;
						if (consentRequestList.length - 1 === i) {
							let successConsents = consentRequestList.filter(
								(el) => el.status === 'SUCCESS'
							);
							global.selectedLSP = [...successConsents];
							this.setState({
								consentRequestList: [...successConsents],
								selectedAccounts: successConsents,
							});
							this.props.updateLoader(false);
							this.context.updateConsentList(successConsents);
							console.log(this.context, 'consentContext');
						}
					} else {
						this.props.updateLoader(true);
						toast.error(result.message);
						if (result.status === 'FAILURE') {
							postMessage('session', 'FAILURE');
						} else if (result.status === 'ERROR') {
							postMessage('session', 'ERROR');
						}
					}
				});
		}
	};

	setTheme = (theme) => {
		this.setState({ theme });
	};
	redirectToExternalTerms = () =>
		window.open('https://finvu.in/terms', '', 'width=600,height=600');

	handleClick = () => {
		this.setState({ isDisable: true, disableType: 'Verify' });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ error: '' });
	};

	render() {
		return this.state.isExpired ? (
			<Expired />
		) : (
			<Box sx={loginLeftStyles.container}>
				<SetTheme
					customDetails={this.props.customDetails}
					setTheme={this.setTheme}
				/>
				<Box sx={loginStyles.container}>
					<Stack
						spacing={'1rem'}
						justifyContent={{ xs: 'space-between', md: 'flex-start' }}
					>
						<Stack sx={loginStyles.otpContainer}>
							<Box width={'100%'} textAlign={'start'}>
								<Typography sx={loginStyles.headingText}>
									{`${global.entityName}`} requires your account information
								</Typography>
								<Box sx={loginStyles.heading}>
									<Typography
										maxWidth={'90%'}
										variant="h6"
										sx={{
											...loginStyles.subHeading,
											fontFamily: this.props.customDetails.fontFamily,
											color: this.props.customDetails.textColor,
										}}
									>
										Proceed with sharing data via Finvu AA
									</Typography>
									<Link
										textAlign={'end'}
										variant="button"
										underline="none"
										flex={1}
										color={this.props.customDetails.primaryColor}
										onClick={() => this.setState({ showDetails: true })}
										sx={loginLeftStyles.help}
									>
										Help
									</Link>
								</Box>
							</Box>
							<Box sx={loginStyles.inputContainer}>
								<TextField
									name="otp"
									fullWidth
									value={this.state.OTP}
									label="OTP"
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									placeholder="Enter OTP"
									variant="outlined"
									onChange={(e) => {
										this.setState({ OTP: e.target.value });
									}}
									sx={{
										'& label.Mui-focused': {
											color: this.props?.customDetails.primaryColor,
										},
										'& .MuiOutlinedInput-root': {
											'&:hover fieldset': {
												borderColor: this.props?.customDetails.primaryColor,
											},
											'&.Mui-focused fieldset': {
												borderColor: this.props?.customDetails.primaryColor,
											},
										},
									}}
								/>
								{this.state.time !== 60 ? (
									<Typography
										fontSize={'0.875rem'}
										fontFamily={this.props.customDetails?.fontFamily}
									>
										Resend OTP in
										<strong
											style={{
												color: this.state.theme?.palette.error.main,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											{' ' + this.state.time} sec
										</strong>
									</Typography>
								) : (
									<Link
										variant="button"
										underline="none"
										color={`${
											this.state.isResend || this.state.isDisable
												? this.props.customDetails?.primaryColor
												: 'grey'
										}`}
										disabled={!this.state.isResend || this.state.isDisable}
										onClick={this.sendOtp}
										sx={{
											cursor: 'pointer',
											textTransform: 'capitalize',
										}}
									>
										Resend OTP
									</Link>
								)}
								<Box
									padding={'1rem'}
									sx={{
										...loginStyles.otpText,
										color: this.props.customDetails.textColor,
										backgroundColor: this.state.theme?.palette.primary.light,
									}}
								>
									OTP sent to registered mobile number
								</Box>
							</Box>
						</Stack>
						<Button
							variant="contained"
							size="large"
							sx={{
								backgroundColor: this.props.customDetails?.primaryColor,
								textTransform: 'capitalize',
								'&:focus': {
									backgroundColor: this.props.customDetails?.primaryColor,
								},
								'&:hover': {
									backgroundColor: this.props.customDetails?.primaryColor,
								},
							}}
							// disabled={!(this.state.OTP?.length === 6) || this.state.isDisable || !this.state.termsAndConditions}
							disabled={!(this.state.OTP?.length === 6) || this.state.isDisable}
							onClick={(e) => this.verifyOtp(e)}
						>
							{
								// (this.state.isDisable && this.state.termsAndConditions && this.state.disableType === 'Verify1') ?
								'Continue'
							}
						</Button>
						<Typography sx={loginStyles.tncLabel}>
							By continuing, you agree to Finvu&nbsp;
							<Link
								component={'button'}
								underline="none"
								fontWeight={700}
								sx={{
									color: this.props.customDetails?.primaryColor,
									fontFamily: this.props.customDetails?.fontFamily,
								}}
								onClick={this.redirectToExternalTerms}
							>
								Terms and Conditions
							</Link>
						</Typography>
					</Stack>
					{/* <Footer customDetails={this.props.customDetails?.primaryColor} /> */}
					<Box sx={loginStyles.footerContainer}>
						<Avatar
							icon={<LockIcon />}
							variant="square"
							sx={{ background: 'none' }}
						>
							<Icon component={LockIcon} sx={loginStyles.lockIcon} />
						</Avatar>
						<Typography
							sx={{
								...loginStyles.footerText,
								color: this.props.customDetails.textColor,
							}}
						>
							100% secure data sharing via RBI regulated Account Aggregator
						</Typography>
						<Avatar
							src={finvuLogo}
							sx={{
								width: '64px',
								'& .MuiAvatar-img': { objectFit: 'contain' },
							}}
						/>
					</Box>
				</Box>
				<LoginRight
					theme={this.state.theme}
					customDetails={this.props.customDetails}
				/>
				<DialogBox
					open={this.state.showDetails}
					handleClose={() => this.setState({ showDetails: false })}
					boxPadding="1.25rem"
					marginTopChildren={'0.75rem'}
					subTitleMaxWidth="75%"
				>
					<LoginRightContent {...this.props} theme={this.state.theme} />
				</DialogBox>
			</Box>
		);
	}
}
