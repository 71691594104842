import { Button, Fab, Grid } from "@mui/material";
import sizeFontCustom from "../Discovery/fontSize.styles"

function AddNewCard(props) {
  const redirect = () => {
    global.fi = global.FIGroups[props.fiType]
    props.history.push(`/home/?number=${global.mobileNumber}&isAddAccount=${true}`, { fiTypes: global.FIGroups[props.fiType] },)
  }
  const handleAddAccount = () => {
    redirect()
    return
    // switch(name){
    //   case "Insurance":
    //     setOpenDOB(true)
    //     break;
    //   case "Mutual Funds":
    //   case "Equities":
    //   case "GSTIN":
    //     setOpenPAN(true)
    //     break;
    //   default:
    //     redirect()
    // }
  }
  return (
    <Grid
      container
      padding={"1rem"}
      onClick={handleAddAccount}
      sx={{
        alignItems: "center",
        borderRadius: "20px",
        border: `1.5px dashed ${props.customDetails?.primaryColor}`,
        textAlign: "left",
      }}
    >
      <div>
        <Fab
          component={"button"}
          disableRipple
          onClick={handleAddAccount}
          color="primary"
          aria-label="add"
          sx={{ minHeight: 0, width: "25px", height: "25px" }}
        >
          +
        </Fab>
        <Button variant="text" sx={sizeFontCustom.subHeading}>
          {props.fiType}
        </Button>
      </div>
    </Grid>
  );
}

export default AddNewCard;
