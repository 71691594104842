import { useEffect } from "react"
import { useMyTheme } from "../../ThemeProviderWrapper";
import { createTheme, useMediaQuery } from "@mui/material";

const SetTheme = ({ setTheme, customDetails }) => {
  const { changeTheme } = useMyTheme();
  const mobileScreen = useMediaQuery('(max-width:900px)');
  useEffect(() => {
    let newTheme = createTheme({
      palette: {
        primary: {
          light: customDetails.secondaryColor,
          main: customDetails.primaryColor,
          white: "#FFFFFF",
        },
        secondary: {
          main: customDetails.secondaryColor,
          light: "#E3E9EE"
        },
        error: {
          main: "#E94B4B",
        },
        success: {
          main: "#20BE79",
          light: "#4de6a3",
        },
        info: {
          main: "#000000",
          light: "#F3F5FD",
        },
        black60: "#81858F",
        black40: "#A5AAB8",
        backgroundMobileSubHeader: "#2B3056",
        textBlack: "#111111",
        tagRed: {
          main: "#CA2626",
          light: "#FDEDED",
        },
      },
      typography: {
        fontFamily: customDetails.fontFamily
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderRadius: "14px",
              borderColor: "#D8E1EE",
            },
            root: {
              '& label': {
                color: customDetails.primaryColor,
              },
              '& label.Mui-focused': {
                color: customDetails.primaryColor,
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: customDetails.primaryColor,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: customDetails.primaryColor,
                },
                '&:hover fieldset': {
                  borderColor: customDetails.primaryColor,
                },
                '&.Mui-focused fieldset': {
                  borderColor: customDetails.primaryColor,
                },
            }
          },
        },
      },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: customDetails.borderRadius,
            },
          },
        },
        MuiListItemAvatar: {
          styleOverrides: {
            root: {
              minWidth: "unset",
            }
          }
        },
        MuiFab: {
          styleOverrides: {
            root: {
              boxShadow: "unset",
              transition: "unset"
            }
          }
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: "unset",
              paddingRight: "0.25rem"
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontSize: mobileScreen ? "1rem" : "0.875rem"
            }
          }
        },
      },
    });
    changeTheme(newTheme);
    setTheme(newTheme)
    return () => null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default SetTheme