import {
	Avatar,
	Box,
	Icon,
	Link,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
// import whyShareStyles from './WhyShare.styles';
import loginStyles from '../Login/Login.styles';
import finvuLogo from '../../assets/finvuLogo.png';
import LockIcon from '@mui/icons-material/Lock';

function WhyShare(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	let description = ['103', '104', '105'].includes(
		global?.consent?.Purpose.code
	)
		? ' in your loan processing or other services. '
		: ' you manage your personal finances. ';
	return (
		<Box
			sx={
				mobileScreen
					? loginStyles.footerContainerMobile
					: loginStyles.footerContainer
			}
		>
			<div
				style={{ flexGrow: 1, display: 'flex', gap: 4, alignItems: 'center' }}
			>
				<Avatar
					icon={<LockIcon />}
					variant="square"
					sx={{
						background: 'none',
						height: mobileScreen ? '16px' : '40px',
						width: mobileScreen ? '16px' : '40px',
					}}
				>
					<Icon component={LockIcon} sx={loginStyles.lockIconMobile} />
				</Avatar>
				<Typography
					sx={{
						...loginStyles.footerTextNew,
						color: props.customDetails.textColor,
					}}
				>
					100% secure data sharing via RBI regulated Account Aggregator
				</Typography>
			</div>
			<Avatar
				src={finvuLogo}
				sx={{
					width: mobileScreen ? '48px' : '64px',
					'& .MuiAvatar-img': { objectFit: 'contain' },
				}}
			/>
		</Box>
	);
}

export default WhyShare;
