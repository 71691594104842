/* eslint-disable react/prop-types */
import { IconButton, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function Drawer(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    open,
    setOpen,
    medWidth,
    closeBtnTop,
    children,
    anchor = "right",
    height = "100vh",
    customDetails,
    showCloseBtn = true
  } = props;

  // eslint-disable-next-line no-unused-vars
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      PaperProps={{
        style: {
          borderRadius: mobileScreen ? `${customDetails.borderRadius} ${customDetails.borderRadius} 0px 0px` : "0px",
          width: mobileScreen ? "100%" : medWidth,
          height: height,
        }
      }}
      onOpen={toggleDrawer("right", true)}
      onClose={toggleDrawer("right", false)}
      disableSwipeToOpen
    >
      {showCloseBtn &&
        <IconButton
          aria-label="close"
          onClick={toggleDrawer("right", false)}
          sx={{
            position: "absolute",
            right: { xs: "1.56rem", md: "2rem" },
            top: closeBtnTop,
            width: "2.5rem",
            height: "2.5rem",
            color: theme.palette.primary.main,
            background: theme.palette.primary.light,
          }}
          disableRipple
        >
          <Close />
        </IconButton>}
      {children}
    </SwipeableDrawer >
  );
}