import React, { useContext, useState } from 'react';
import ConsentListContext from '../../Context/ConsentContext';
import { useTheme } from '@emotion/react';
import {
	Avatar,
	Box,
	CardActionArea,
	Checkbox,
	Chip,
	Divider,
	ListItemIcon,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';

import sizeFontCustom from '../../Discovery/fontSize.styles';
import Field from './Field';
import {
	capitalize,
	dateFormat,
	getConsentModeText,
	getFrequencyUnit,
} from '../../../hepler/functions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChipSet from './ChipSet';

const ConsentCardFull = (props) => {
	//Props
	const {
		consentDetails,
		customDetails,
		id,
		selectedConsentsState,
		handleIsActive,
		isMultiConsent,
		children,
		isLast,
	} = props;

	//Context
	const consentContext = useContext(ConsentListContext);
	const consentHandle = consentDetails?.ConsentHandle;
	const isAccepted =
		consentContext.acceptedConsentsMultiConsent.includes(consentHandle);
	const isDenied =
		consentContext?.deniedConsentsMultiConsent.includes(consentHandle);
	const consentAction = isAccepted ? 'Accepted' : isDenied ? 'Denied' : null;
	// const isActiveFlag = consentContext?.activeSlide === id - 1;
	// console.log('selectedConsentsState: in CCF', selectedConsentsState);
	const isActiveFlag = selectedConsentsState?.includes(consentHandle)
		? true
		: false;
	// console.log('isActiveFlag for consentHandle: ', isActiveFlag, consentHandle);
	const consentList = consentContext.consentList;
	const selectedConsents = consentContext.selectedConsents;
	// console.log('context selectedConsents: ', selectedConsents);
	const numberOfConsents = consentList.length;

	//Themeing
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [openToolTip, setOpenToolTip] = useState(false);

	const handleClick = () => {
		setOpenToolTip((prevState) => !prevState);
	};
	// console.log('ConsentHandle: ', consentHandle);
	return (
		<Box
			height={consentAction && mobileScreen ? '30vh' : 'none'}
			sx={{ mx: 1, mb: 1 }}
		>
			<Paper
				elevation={0}
				sx={{
					m: mobileScreen ? '0' : '0.25rem 0rem 0.25rem 0',
					flex: 1,
					height: '100%',

					width: mobileScreen ? '100%' : 'auto',
				}}
			>
				<CardActionArea
					// onClick={() => console.log('click')}
					sx={{
						boxShadow:
							numberOfConsents === 1
								? 5
								: isActiveFlag
								? 5
								: mobileScreen
								? null
								: null,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						padding: mobileScreen
							? `0.5rem 0.5rem ${isLast ? '5rem' : '1rem'} 0.5rem`
							: '1.25rem 2.75rem 1rem 1.5rem',
						borderLeft:
							numberOfConsents === 1 && !mobileScreen
								? `5px solid ${theme.palette.primary.main}`
								: !mobileScreen
								? isActiveFlag
									? `5px solid ${theme.palette.primary.main}`
									: `5px solid ${theme.palette.secondary.main}`
								: `5px solid ${theme.palette.secondary.main}`,
						borderTop: mobileScreen
							? `5px solid ${theme.palette.primary.main}`
							: '',
						backgroundColor:
							numberOfConsents === 1
								? `5px solid ${theme.palette.primary.main}`
								: !mobileScreen
								? isActiveFlag
									? theme.palette.primary.light
									: 'white'
								: theme.palette.primary.light,
						borderBottom:
							numberOfConsents === 1
								? `thin solid rgba(0, 0, 0, 0.12)`
								: !mobileScreen
								? 'thin solid rgba(0, 0, 0, 0.12)'
								: 'none',
						gap: mobileScreen ? '0.5rem' : '0.5rem',
						width: '100%',
						borderBottomRightRadius: mobileScreen ? 0 : 'inherit',
						borderBottomLeftRadius: mobileScreen ? 0 : 'inherit',
					}}
				>
					<Stack
						justifyContent={'space-between'}
						gap={mobileScreen ? '1rem' : '1.625rem'}
						width={'100%'}
					>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							{/* Number */}
							<Box
								display={'flex'}
								flexDirection={'row'}
								width="100%"
								height={isMultiConsent && mobileScreen ? '100%' : 'auto'}
								alignItems={'center'}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{/* <Avatar
										sx={{
											...sizeFontCustom.cardTitle,
											backgroundColor: customDetails?.primaryColor,
											height: mobileScreen ? '22px' : '28px',
											width: mobileScreen ? '22px' : '28px',
											mr: '0.678rem',
										}}
									>
										{id}
									</Avatar> */}
								</Box>
								{/* FIELD */}
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										pl: mobileScreen ? '0.25rem' : '0.5rem',
										flexGrow: 1,
										maxHeight: '100%',
									}}
								>
									<div>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: 4,
												}}
											>
												{numberOfConsents > 1 ? (
													<Chip
														variant="outlined"
														size="small"
														label={`${id} of ${numberOfConsents} `}
														sx={{
															// mr: 0.5,
															// mt: 0.5,
															border: '1px solid #81858F',
															fontWeight: 700,
															color: '#81858F',
															'& .MuiChip-label': {
																fontSize: '0.675rem',
															},
														}}
													/>
												) : null}
												<Typography
													sx={{
														...sizeFontCustom.subHeading,
														fontFamily: customDetails?.fontFamily,
														textAlign: 'left',
													}}
												>
													Consent Purpose
												</Typography>

												<Tooltip
													title="Purpose of the consent"
													open={openToolTip}
													onClick={handleClick}
												>
													<ListItemIcon>
														<InfoOutlinedIcon
															color="primary"
															sx={{ fontSize: 16 }}
														/>
													</ListItemIcon>
												</Tooltip>
											</div>
											{numberOfConsents > 1 ? (
												<Checkbox
													color={'primary'}
													checked={isActiveFlag}
													key={Math.random()}
													name={consentDetails?.ConsentHandle}
													onClick={() =>
														handleIsActive(consentDetails.ConsentHandle)
													}
												/>
											) : null}
										</div>
									</div>
									<Typography
										sx={{
											...sizeFontCustom.field,
											// maxWidth: mobileScreen ? '60vw' : '19vw',

											fontFamily: customDetails?.fontFamily,
											textAlign: 'left',
											fontWeight: 700,
										}}
									>
										{consentDetails?.Purpose?.text}
									</Typography>
								</Box>
							</Box>
							{consentAction && (
								<Chip
									variant="outlined"
									label={consentAction}
									color={isAccepted ? 'success' : 'error'}
								/>
							)}
						</Box>
					</Stack>
					{!consentAction && (
						<div>
							<Divider variant="light" flexItem sx={{ mb: 1 }} />
							<Stack
								component="ul"
								sx={{
									px: '0.25rem',
									mx: '0rem',
									mt: '0rem',
									width: '100%',
									gap: mobileScreen ? '0rem' : '0.2rem',
								}}
							>
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: '1fr 1fr',
										columnGap: '2rem',
										margin: '0 0 0.4rem 0',
									}}
								>
									<Field
										property="Data fetch frequency"
										value={
											consentDetails?.fetchType === 'ONETIME'
												? 'ONE TIME'
												: `${
														consentDetails?.Frequency?.value
												  } TIMES ${getFrequencyUnit(
														consentDetails?.Frequency?.unit
												  )}`
										}
										customDetails={customDetails}
									/>
									<Field
										property={`Data Use`}
										value={getConsentModeText(
											consentDetails?.mode,
											consentDetails?.DataLife?.value,
											consentDetails?.DataLife?.unit
										)}
										customDetails={customDetails}
									/>
								</div>
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: '1fr 1fr',
										columnGap: '2rem',
										margin: '0 0 0.4rem 0',
									}}
								>
									<Field
										property="Data fetch from"
										value={dateFormat(consentDetails?.DataDateTimeRange.from)}
										customDetails={customDetails}
									/>
									<Field
										property="Data fetch until"
										value={dateFormat(consentDetails?.DataDateTimeRange?.to)}
										customDetails={customDetails}
									/>
								</div>
								<Field
									property="Account Information"
									customDetails={customDetails}
									// TODO: tooltip text
									tooltipText="Account information details"
								>
									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: 1,
											margin: '0 0 0.4rem 0',
										}}
									>
										{consentDetails?.consentTypes.map((type) => (
											<Chip
												key={type}
												variant="outlined"
												size="small"
												label={capitalize(type)}
												sx={{
													mr: 0.5,
													mt: 0.5,
													color: '#81858F',
													fontSize: '0.675rem',
												}}
											/>
										))}
									</div>
								</Field>
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: '1fr 1fr',
										columnGap: '2rem',
										margin: '0 0 0.4rem 0',
									}}
								>
									<Field
										property="Consent requested on"
										value={dateFormat(consentDetails?.startTime, true)}
										customDetails={customDetails}
									/>
									<Field
										property="Consent expires on"
										value={dateFormat(consentDetails?.expireTime, true)}
										customDetails={customDetails}
										valueStyles={{ color: 'red' }}
									/>
								</div>
							</Stack>
						</div>
					)}
					<Divider variant="light" flexItem sx={{ mt: '-0.75rem' }} />

					<Field
						property="Account Types Requested"
						customDetails={customDetails}
						styles={{ width: '100%' }}
					>
						<ChipSet fiTypes={consentDetails?.fiTypes} />
					</Field>
					<div style={{ width: '100%' }}>{children}</div>
				</CardActionArea>
			</Paper>
		</Box>
	);
};

export default ConsentCardFull;
