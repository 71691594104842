/* eslint-disable react/prop-types */
import { Grid, Typography, useTheme } from "@mui/material";
import sizeFontCustom from "../Discovery/fontSize.styles"

function ConsentsDetailsCardLSP({ title, children }) {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={5.7}
      md={1.9}
      sx={{
        background: `${theme.palette.secondary.main}`,
        padding: "0.5rem",
        borderRadius: "0.75rem",
        // margin: "0 1.15rem 0.9rem 0",
      }}
    >
      <Typography
        sx={{
          ...sizeFontCustom.subHeading,
          color: theme.palette.black60,
          marginBottom: "0.6rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          wordWrap: "break-word",
          ...sizeFontCustom.subHeading,
          fontWeight: 500,
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
}

export default ConsentsDetailsCardLSP;
