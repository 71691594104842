import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import sizeFontCustom from '../Discovery/fontSize.styles';
import { getConsentTitle } from '../../hepler/functions';

function RequestedConsentsHeading(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Box
			sx={{
				width: 'inherit',
				padding: { xs: '1rem 1rem', md: '1rem 1.875rem' },
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: mobileScreen ? 'flex-start' : 'center',
					flexDirection: mobileScreen ? 'column-reverse' : 'row',
					justifyContent: 'start',
					gap: mobileScreen ? '0.375rem' : 'none',
					marginBottom: mobileScreen ? '0.375rem' : '0.625rem',
				}}
			>
				<Typography
					noWrap
					variant="h6"
					sx={{
						fontSize: { xs: '1rem', md: '1.25rem' },
						mr: '1.23rem',
						display: 'flex',
						alignItems: 'center',
						fontWeight: 900,
						fontFamily: props.customDetails.fontFamily,
					}}
				>
					{getConsentTitle(props.consentsNum)}
					{/* <Avatar
            sx={{
              margin: "0.5rem",
              width: { xs: 23, md: 28 },
              height: { xs: 23, md: 28 },
              fontSize: { xs: "0.75rem", md: "1rem" },
              bgcolor: `${theme.palette.info.light}`,
              color: "grey",
              fontWeight: 400
            }}
          >
            {props.consentsNum}
          </Avatar> */}
				</Typography>
				{mobileScreen && (
					<Typography
						sx={{
							...sizeFontCustom.subHeading,
							color: theme.palette.black60,
							fontFamily: props.customDetails.fontFamily,
						}}
					>
						{`AA handle: ${global?.userId}`}
					</Typography>
				)}
			</Box>
			<Typography
				sx={{
					...sizeFontCustom.heading,
					textAlign: 'start',
					fontFamily: props.customDetails.fontFamily,
					color: theme.palette.black60,
				}}
			>
				Approve consent from{' '}
				<strong style={{ color: 'black' }}>{global.entityName}</strong> to share
				your account information.
			</Typography>
		</Box>
	);
}

export default RequestedConsentsHeading;
