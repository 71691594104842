import { Avatar, Box, Button, CardActionArea, Chip, Divider, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import EastIcon from '@mui/icons-material/East';
import ConsentDetailSingle from "./ConsentDetailSingle";
import { capitalize, dateFormat } from "../../hepler/functions";
import Drawer from "../Drawer/Drawer";
import ConsentDetails from "./ConsentDetails";
import { useContext, useState } from "react";
import sizeFontCustom from "../Discovery/fontSize.styles";
import ConsentListContext from "../Context/ConsentContext";
import ConsentDetailSingleDialog from "./ConsentsDetailsSingleDialog";

function ConsentCard(props) {
  const { consentDetails, customDetails, id, handleIsActive, isMultiConsent, children } = props;
  const consentContext = useContext(ConsentListContext)
  const consentHandle = consentDetails?.ConsentHandle;
  const isAccepted = consentContext?.acceptedConsentsMultiConsent.includes(consentHandle);
  const isDenied =  consentContext?.deniedConsentsMultiConsent.includes(consentHandle);
  const consentAction = isAccepted?"Accepted":isDenied? "Denied": null
  const isActiveFlag = consentContext?.activeSlide === id-1;
    const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showDetails, setShowDetails] = useState(false)

  const handleDetails = () => {
    setShowDetails(true);
  }

  const acInfoTransform = (el, infoType, i) => {
    return (
      <span key={i}>
        {capitalize(el).split('_').join(' ')}
        {infoType?.length - 1 === i ? "" : ", "}
      </span>
    );
  };

  return (
    <Box 
      height={consentAction&&mobileScreen?"30vh":"none"}    
      width={ mobileScreen ? "90vw" : "100%"}
    >
      <Paper
        elevation={0}
        sx={{
          m: mobileScreen ? "0 0.25rem 0 0" : "0.25rem 0rem 0.25rem 0",
          flex: 1,
          height: "100%",
          // borderLeft: mobileScreen && id === 1 ? `15px solid ${theme.palette.secondary.main}` : null,
          width: mobileScreen ? "90vw" : "auto"
        }}>
        <CardActionArea
          onClick={() => handleIsActive(id, consentDetails)}
          sx={{
            boxShadow: isActiveFlag ? 5 : mobileScreen ? null : null,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: mobileScreen ? "1rem 0.625rem 0 0.625rem" : "1.25rem 2.75rem 0 1.5rem",
            borderLeft:
              !mobileScreen
                ? isActiveFlag
                  ? `5px solid ${theme.palette.primary.main}`
                  : `5px solid ${theme.palette.secondary.main}`
                : `5px solid ${theme.palette.secondary.main}`,
            borderTop:
              mobileScreen
                ? isActiveFlag
                  ? `5px solid ${theme.palette.primary.main}`
                  : `5px solid ${theme.palette.secondary.main}`
                : `5px solid ${theme.palette.secondary.main}`,
            backgroundColor: isActiveFlag ? theme.palette.primary.light : "white",
            borderBottom: mobileScreen ? "thin solid rgba(0, 0, 0, 0.12)" : 'none',
            gap: mobileScreen ? "0.5rem" : "1rem",
            width: "100%",
            borderBottomRightRadius: mobileScreen ? 0 : "inherit",
            borderBottomLeftRadius: mobileScreen ? 0 : "inherit",
          }}>
          <Stack
            justifyContent={"space-between"}
            gap={mobileScreen ? "1rem" : "1.625rem"}
            width={"100%"}
          >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box display={"flex"} flexDirection={"row"} height={isMultiConsent && mobileScreen ? "64px" : "auto"} alignItems={"center"} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                  <Avatar sx={{
                    ...sizeFontCustom.cardTitle,
                    backgroundColor: customDetails?.primaryColor,
                    height: mobileScreen ? "22px" : "28px",
                    width: mobileScreen ? "22px" : "28px",
                    mr: "0.678rem",
                  }}>
                    {id}
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    pl: mobileScreen ? "0.25rem" : "0.5rem",
                  }}
                >
                  {/* <Box sx={{ width: "100%", textAlign: "center", display: "flex", flexDirection: "row" }}>
                    <Typography sx={sizeFontCustom.cardTitle}>
                      Expires on &nbsp;
                    </Typography>
                    <Typography
                      sx={{
                        ...sizeFontCustom.cardTitle,
                        color: theme.palette.error.main,
                        fontWeight: 700,
                      }}
                    >
                      {dateFormat(consentDetails?.expireTime, false)}
                    </Typography>
                  </Box> */}
                  <Typography sx={{
                    ...sizeFontCustom.heading,
                    maxWidth: mobileScreen ? "60vw" : "19vw",
                    fontWeight: 700,
                    fontFamily: customDetails?.fontFamily,
                    textAlign: "left",
                  }}>
                    {consentDetails?.Purpose?.text}
                  </Typography>
                </Box>
              </Box>
              {consentAction && <Chip
                variant="outlined"
                label={consentAction}
                color={isAccepted? "success": "error"}
              />}
            </Box>
          </Stack>
          {!consentAction && 
            <>
              <Divider variant="light" flexItem />
              <Stack component="ul" sx={{ pl: "1.5rem", mt: "0rem" }} spacing="0.2rem">
                <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem", paddingBottom: "0rem" }}>
                  <ConsentDetailSingle
                    customDetails={customDetails}
                    title={<>Data fetch &nbsp;</>}
                    flexDirection="row"
                    date={true}
                    fromDate={dateFormat(consentDetails?.DataDateTimeRange?.from, false)}
                    toDate={dateFormat(consentDetails?.DataDateTimeRange?.to, false)}
                  />
                </li>
                <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem" }}>
                  <ConsentDetailSingle
                    customDetails={customDetails}
                    title=
                    {consentDetails?.fetchType === 'ONETIME' ?
                      'Information will be fetched one time' :
                      `Information will be fetched up to ${consentDetails?.Frequency?.value} 
                                          times a ${capitalize(consentDetails?.Frequency?.unit)}`}
                    flexDirection="row"
                  />
                </li>
                <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem" }}>
                  <ConsentDetailSingle
                    customDetails={customDetails}
                    title={<>Data   {capitalize(consentDetails?.mode)} &nbsp; </>}
                    description={<>Until  {capitalize(consentDetails?.DataLife?.value + " " + consentDetails?.DataLife?.unit)}</>}
                    flexDirection="row"
                  />
                </li>
                <li style={{ paddingLeft: mobileScreen ? "1rem" : "1.5rem" }}>
                  <ConsentDetailSingle
                    customDetails={customDetails}
                    title={<>{consentDetails?.consentTypes.map((el, i) => acInfoTransform(el, consentDetails?.consentTypes, i))} will be shared</>}
                  />
                </li>
              </Stack>
            </>}
            <Divider variant="light" flexItem sx={{mb:"1.25rem", mt:"-0.75rem"}} />

            <Box 
              sx={{
                  display: "flex", 
                  justifyContent:"space-between", 
                  width: "100%", 
                  alignItems:"center", 
                  m: { xs: `-1rem 0rem ${isMultiConsent ? "1.5rem" : "0.5rem"} 0`, md: `${consentAction?"-0.5rem":"-1.5rem"} 0rem 0.5rem 0.3rem` },}}>
              <Box sx={{ textAlign: "center", display: "flex", flexDirection: "row" }}>
                  <Typography sx={sizeFontCustom.subHeading}>
                    Expires on &nbsp;
                  </Typography>
                  <Typography
                    sx={{
                      ...sizeFontCustom.subHeading,
                      color: theme.palette.error.main,
                      fontWeight: 700,
                    }}
                  >
                    {dateFormat(consentDetails?.expireTime, false)}
                  </Typography>
                </Box>
            <Button
              variant="text"
              onClick={handleDetails}
              endIcon={<EastIcon />}
              sx={{
                ...sizeFontCustom.subHeading,
                fontWeight: 700,
                fontFamily: customDetails?.fontFamily,
                p:0,
                textTransform: "none",
              }}>
             More Details
            </Button>
            </Box>
            <ConsentDetailSingleDialog title={""} xs={12}>
          <Link underline='none'>Select Accounts to give access</Link>
        </ConsentDetailSingleDialog>
        </CardActionArea>
      </Paper>
      {children}
    </Box>
  )
}

export default ConsentCard;