import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import ConsentDetailSingleDialog from './ConsentsDetailsSingleDialog'
import { capitalize, dateFormat, acInfoTransform } from '../../hepler/functions';

function ConsentDetails(props) {
  const { consent } = props;

  return (
    <Box sx={{ p: "1rem" }}>
      <Typography sx={{ textAlign: "center", pb: "0.5rem", fontSize: "1rem", fontWeight: 700 }}>
        Consent Details
      </Typography>
      <Divider />
      <Grid
        container
        // spacing={"1rem"}
        gap={"0.75rem"}
        sx={{
          display: "flex",
          justifyContent: { sm: "space-between" },
          pt: "1rem",
        }}
      >
        <ConsentDetailSingleDialog title={"Purpose"} xs={12}>
          {consent?.Purpose?.text}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Purpose Code"} xs={12}>
          {consent?.Purpose?.code}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Requested On"}>
          {dateFormat(consent?.startTime, true)}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Expires On"}>
          {dateFormat(consent?.expireTime, true)}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Data Requested From Date"}>
          {dateFormat(consent?.DataDateTimeRange.from)}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Data Requested To Date"}>
          {dateFormat(consent?.DataDateTimeRange?.to)}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Data " + capitalize(consent?.mode) + " Until"}>
          {consent?.DataLife?.value} {capitalize(consent?.DataLife?.unit)}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={consent?.fetchType === "ONETIME" ? "One-Time" : "Periodic"} xs={12}>
          {consent?.fetchType === "ONETIME"
            ? "Information fetch one time"
            : `Information fetch ${consent?.Frequency.value} times a ${capitalize(consent?.Frequency?.unit)}`}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Account Information"} xs={12}>
          {consent?.consentTypes.map((el, i) => acInfoTransform(el, consent?.consentTypes, i))}
        </ConsentDetailSingleDialog>
        <ConsentDetailSingleDialog title={"Account Types"} xs={12}>
          {consent?.fiTypes.map((el, i) => acInfoTransform(el, consent?.fiTypes, i))}
        </ConsentDetailSingleDialog>
      </Grid>
    </Box>
  )
}

export default ConsentDetails;
