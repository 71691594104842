import { TextField, InputAdornment, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import SearchBarStyle from "./SearchBar.styles";
import sizeFontCustom from "../Discovery/fontSize.styles"

SearchBar.propTypes = {
  onSearch: PropTypes.func,
};

function SearchBar({ onSearch }) {
  const theme = useTheme();

  const handleSearch = (event) => {
    onSearch(event.target.value);
  };

  return (
    <TextField
      label={"Search Institutions "}
      variant="outlined"
      fullWidth
      InputLabelProps={{
        sx: {
          ...sizeFontCustom.heading,
          "&.MuiOutlinedInput-notchedOutline": { ...sizeFontCustom.heading },
          flex: "inline",
          borderRadius: "40px"
        },
      }}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "grey" }} height="1.5rem" width="1.5rem" />
          </InputAdornment>
        ),
      }}
      sx={{ ...SearchBarStyle.textField, background: theme.palette.secondary.main }}
    />
  );
}

export default SearchBar;
