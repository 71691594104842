import { Box, Chip, Tooltip, Typography } from '@mui/material'
import { transformFiType } from '../../hepler/functions'
import { useState } from 'react';
import sizeFontCustom from '../Discovery/fontSize.styles';

function ConsentFitypesChipSet({fiTypes}) {
  const maxFiSize = 3;
  const [open, setOpen] = useState(false);
  const toolTipFitype = () => fiTypes.slice(maxFiSize).map((fi)=><Typography sx={sizeFontCustom.toolTipText}>{transformFiType(fi)}</Typography>)
  return (
    <>
    {fiTypes.slice(0,maxFiSize).map((info) => (
      <Chip
        variant="outlined"
        label={transformFiType(info)}
        key={info}
        sx={{ margin: "0 0.5rem 0.5rem 0", fontSize: "0.75rem" }}
      />
    ))}
    {fiTypes.length-maxFiSize>0&&
      <Tooltip
        open={open}
        onClose={()=>setOpen(false)}
        title={<Box>{toolTipFitype()}</Box>}
      >
        <Chip
        variant="outlined"
        onClick={()=>setOpen(true)}
        label={`+ ${fiTypes.length-maxFiSize} more`}
        sx={{ margin: "0 0.5rem 0.5rem 0", fontSize: "0.75rem" }}
      />
      </Tooltip>
      }
  </>
  )
}

export default ConsentFitypesChipSet