/* eslint-disable no-loop-func */
import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AuthApis from '../hepler/authApis.service';
import RequestedConsents from './paymentConsent/RequestedConsents';
import "./AccountDiscovery.styles.css"
import AccountDiscoveryContainer from './Discovery/AccountDiscoveryContainer';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import DialogBox from './Dialog Box/Dialog Box';
import VerifyDetailsDOB from './Dialog Box/VerifyDetailsDOB';
import SetTheme from './common/SetTheme';

function AccountDiscovery(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [accountState, setAccountState] = useState({
    isProceed: true,
    discoveredAccounts: [],
    selectedAccounts: [],
    linkedAccounts: [],
    isResend: true,
    isLoding: true,
    selectedBank: null,
    isSkip: false,
    isDisable: false,
    consentDetails: global.consentList ? global.consentList : props?.location?.state?.data,
    noDiscoveredAccounts: null
  })
  const [isPanRequired, setIsPanRequired] = useState(global?.pan === "Y"?false:true);
  const [isDobRequired, setIsDobRequired] = useState(global?.dob === "Y"?false:true);
  const [openDOB, setOpenDOB] = useState(false);
  const [dob, setDob] = useState(global?.dob);
  const [pan, setPan] = useState(global?.pan);
  const authApis = new AuthApis();
  useEffect(() => {

      checkIdentifiers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinkedAcc = () => {
    props.updateLoader(false);
    authApis.getLinkedAccounts().then((res) => {
      if (!res) {
        toast.error('Something went wrong,Please try again');
        props.updateLoader(false);
      }
      if (res?.status === 'SUCCESS') {
        let linkedacc = res.LinkedAccounts.map((el) => el.accRefNumber)
        setAccountState((prevState) =>
        ({
          ...prevState,
          linkedAccounts: [...accountState.linkedAccounts, ...linkedacc]
        }));
        if (props.match.params.fid) {
          discoverAccount(linkedacc);
        } else {
          if (!global.isAddAccount) {
            if (global?.institutionType === 'LSP') {
              props.updateLoader(false);
              props.history.push({
                pathname: `/select-lender`,
              });
            } else {
              props.updateLoader(false);
              props.history.push({
                pathname: `/payment-consent`,
                state: { data: res.LinkedAccounts },
            });}
          } else {
            discoverAccount();
          }
        }
      } else {
        console.log(res?.message);
        if (res?.status === 'FAILURE') {
          toast.error(res?.message)
          setTimeout(() => {

            props.history.push({
              pathname: `/consent-status`,
              state: {
                status: 'Consent denied',
                statusCode: 403,
              }
            });
          }, 10000);
        }
        if (props.match.params.fid) {
          discoverAccount();
        } else {
          if (global?.institutionType === 'LSP') {
            props.updateLoader(false);
            props.history.push({
              pathname: `/select-lender`,
            });
          } else {
          props.updateLoader(false);
          props.history.push('/payment-consent');
        }
        }
      }
    });
  };
  const getFipIdentifiersInfo = async (fid) => {
    const fipIdentifiers = {};
    const fipIdentifiersTypes = new Set();
    await authApis.fipDetails(fid).then((res) => {
      if (res?.status === "SUCCESS") {
        res?.FipDetails[0]?.fipFiTypeIdentifiers.forEach((x) => {
          x?.identifiers.forEach((id) => {
            fipIdentifiers[id.type] = id.category;
            fipIdentifiersTypes.add(id.type);
          });
        });
      } else if (res?.status === "FAILURE" && res?.message === "Session Error.") {
        toast.error("Session Error")
      }
    });
    return fipIdentifiers;
  };

  const checkIdentifiers = async () => {
    if (!props.match.params.fid) {
      return getLinkedAcc()
    }
    const fips = props.match.params.fid?.split(',')
    const fipIdentifiersTypes = []
    for (const fid of fips) {
      fipIdentifiersTypes.push(...Object.keys(await getFipIdentifiersInfo(fid)));
    }
    if (
      isPanRequired &&
      isDobRequired &&
      fipIdentifiersTypes.includes("DOB") &&
      fipIdentifiersTypes.includes("PAN")
    ) {
      setIsDobRequired(true);
      setIsPanRequired(true);
      setOpenDOB(true);
    } else if (isPanRequired && fipIdentifiersTypes.includes("PAN")) {
      setIsPanRequired(true);
      setIsDobRequired(false);
      setOpenDOB(true);
    } else if (isDobRequired && fipIdentifiersTypes.includes("DOB")) {
      setIsDobRequired(true);
      setIsPanRequired(false);
      setOpenDOB(true);
    } else {
      getLinkedAcc()
    }
  };
  const handleDOB = () => {
    setOpenDOB(false);
    getLinkedAcc();
  };


  const discoverAccount = async (linkedacc = [], fipId, phoneNumber) => {

    let results = [];
    let noResultsFips = [];
    const notLinkedDiscoveredAccounts = [];
    linkedacc = linkedacc.length > 0 ? linkedacc : accountState.linkedAccounts
    const fips = fipId ? fipId : props.match.params.fid?.split(',')
    phoneNumber = phoneNumber ? phoneNumber : props.match.params.number
    for (const fid of fips) {
      const fipIdentifiersInfo = await getFipIdentifiersInfo(fid);
      const fipIdentifiers = [];
      let idValue = phoneNumber;
      for (const [key, value] of Object.entries(fipIdentifiersInfo)) {
        if (key === "PAN" ) {
          idValue = pan;
        }
        if (key === "DOB") {
          idValue = dob;
        }
        if((key === "PAN"&&!isPanRequired)||(key === "DOB" && !isDobRequired)){
          continue
        }
        fipIdentifiers.push({ category: value, type: key, value: idValue });
      }
      let fipObj = {}
      await window.finvuClient
        .discoverAccounts(fid, fipIdentifiers)
        .then((res) => {
          props.updateLoader(false);
          if (!res) {
            toast.error('Something went wrong,Please try again');
          }
          if (res?.status === 'SUCCESS') {
            console.log(res,"discovery")
            const notLinked = res.DiscoveredAccounts.filter((acc) => !linkedacc.includes(acc.accRefNumber));
            notLinkedDiscoveredAccounts.push(...notLinked)
            fipObj = { fipId: fid, discoveredAccounts: res.DiscoveredAccounts, phoneNumber: phoneNumber }
            if (typeof fipId !== 'undefined') {
              noResultsFips = accountState.noDiscoveredAccounts.fips
              const index = noResultsFips.indexOf(fid);
              if (index > -1) {
                noResultsFips.splice(index, 1);
              }
            }
            if(res?.DiscoveredAccounts?.length!==0){
              results.push(fipObj)
            }else if (noResultsFips.indexOf(fid) === -1) {
              noResultsFips.push(fid)
            }
          } else {
            if (res?.status === "FAILURE" && res?.message === "Session Error.") {
              toast.error(res?.message);
              //   // postMessage('session', 'FAILURE');
              //   setTimeout(() => {

              //     this.props.history.push({
              //         pathname: `/consent-status`,
              //         state: {
              //             status: 'Consent denied',
              //             statusCode: 403,
              //         }
              //     });
              //   }, 10000);
              console.log("post receiving failure", results)
            }

            fipObj = { fipId: fid, discoveredAccounts: [], phoneNumber: phoneNumber }
            if (noResultsFips.indexOf(fid) === -1) {
              noResultsFips.push(fid)
            }
          }
        });
    }
    setAccountState({
      ...accountState,
      discoveredAccounts: [...accountState.discoveredAccounts, ...results].sort((a, b) => b.discoveredAccounts.length - a.discoveredAccounts.length),
      isLoding: false,
      selectedAccounts: notLinkedDiscoveredAccounts,
      linkedAccounts: linkedacc,
      noDiscoveredAccounts: { fips: noResultsFips, phoneNumber: phoneNumber }
    });
  };

  const handleBack = () =>
    props.history.push(
      `/home/?number=${props.match.params?.number
      }&isAddAccount=${true}`
    )
  const setTheme = theme => {
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpenDOB(false);
    getLinkedAcc();
  };
  return (
    <>
      <SetTheme customDetails={props.customDetails} setTheme={setTheme} />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "start", md: "center" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
          minHeight: mobileScreen ? "auto" : '100vh',
          alignItems: "start",
        }}>

        {!accountState.isLoding
          ?
          <>
            <RequestedConsents
              customDetails={props.customDetails}
              consentDetailsList={accountState.consentDetails}
              {...props}
            />
            <AccountDiscoveryContainer {...props} discoverAccount={discoverAccount} accountState={accountState} handleBack={handleBack} />
          </>
          :
          (props.match.params.fid && 
            <>
              <RequestedConsents
                customDetails={props.customDetails}
                consentDetailsList={accountState.consentDetails}
                {...props}
              />
              { !openDOB?
                <Box
                  sx={{
                    flex: 2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: mobileScreen ? "center" : "center",
                    minHeight: '100vh',
                    minWidth: { xs: "100vw", md: "auto", },
                    background: theme.palette.primary.light,
                  }}>
                  <Typography sx={{
                    fontSize: "1.25rem",
                    fontWeight: 900,
                    mb: "0.4rem"
                  }}>
                    Approaching your institution!
                  </Typography>
                  <Typography sx={{
                    fontSize: "1rem",
                    mb: "1.5rem",
                    color: "#81858F"
                  }}>
                    Sit back and relax while we discover your accounts
                  </Typography>
                  <div class="dots-5"></div>
                </Box>:
                <Box
                sx={{
                  flex: 2,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: mobileScreen ? "start" : "center",
                  minHeight: '100vh',
                  minWidth: { xs: "100vw", md: "auto", },
                  background: theme.palette.primary.light,
                  pt: mobileScreen ? '2rem' : "none",
                }}/>}
            </>
          )
        }
      </Box>
      <DialogBox
        open={openDOB}
        handleClose={handleClose}
        title="Verify Your Details"
        subTitle={`Please enter ${(isDobRequired ? "Date of Birth" : "") +
          (isDobRequired && isPanRequired ? " and " : "") +
          (isPanRequired ? "PAN" : "")
          }  in order to get your details`}
        boxPadding="1.25rem"
        subTitleMaxWidth="80%"
      >
        <VerifyDetailsDOB
          handleClick={handleDOB}
          date={dob}
          setDate={setDob}
          pan={pan}
          setPan={setPan}
          isPanRequired={isPanRequired}
          isDobRequired={isDobRequired}
        />
      </DialogBox>
    </>
  )
}

export default AccountDiscovery