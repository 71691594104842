const headerStyles = {
  container: { flexGrow: 1 },
  appBar: { position: "fixed", background: "#FFFFFF" },
  p0: { padding: "0!important" },
  imageBox: {
    display: "flex",
    justifyContent:"space-between",
    alignItems: "center",
    margin: "1.15rem 1.875rem 1.15rem 0.5rem",
    width: "100vw"
  },
  logoContainer: {
    width: { xs: "4rem", md: "6.25rem" },
    display: "flex",
    justifyContent: "center",
    height: { xs: "2rem", md: "2.125rem" }
  },
  logo: { display: "block", objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }
}

export default headerStyles;