import { Box, List, Typography, useMediaQuery, useTheme } from '@mui/material'
import LoginRightListItem from './LoginRightListItem';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
function LoginRightContent(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box width={"100%"}>
        <Typography sx={{
          textAlign: "start",
          fontSize: mobileScreen ? "1rem" : "1.875rem",
          fontWeight: 700,
          mb: !mobileScreen ? "1.25rem" : "0.5rem"
        }}
        >
          Share your Financial data instantly
        </Typography>
        <Typography sx={{
          textAlign: "start",
          color: props.customDetails.primaryColor,
          fontSize: mobileScreen ? "1.5rem" : "2.75rem",
          fontWeight: 900
        }}
        >
          3 Quick Steps
        </Typography>
      </Box>
      <List>
        <LoginRightListItem
          number={1}
          text={"For first time users link your account with an OTP."}
          {...props}
        />
        <LoginRightListItem
          number={2}
          text={"Select your accounts for sharing information from your account."}
          {...props} />
        <LoginRightListItem
          number={3}
          text={"Approve consent to share your account information securely."}
          {...props} />
      </List>
      {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "1.25rem", mb: "0.75rem" }}>
        <AccountBalanceIcon
          fontSize={mobileScreen ? "small" : 'large'}
          sx={{ mr: "0.5rem", color: props.customDetails.primaryColor }}
        />
        <Box width={"150px"}>
          <LinearProgress sx={{ color: props.customDetails.primaryColor }} />
        </Box>
        <AccountBalanceIcon
          fontSize={mobileScreen ? "small" : 'large'}
          // color='primary'
          sx={{ ml: "0.5rem", color: props.customDetails.primaryColor }}
        />
      </Box> */}
    </>
  )
}

export default LoginRightContent