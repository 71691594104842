import { Avatar, AvatarGroup, Box, Button, CardActionArea, Divider, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import sizeFontCustom from "../Discovery/fontSize.styles";
import { useContext } from "react";
import ConsentListContext from "../Context/ConsentContext";

function LspSteps(props) {
  const {  customDetails, id, children, title, subtitle } = props;
  const consentContext = useContext(ConsentListContext)
  const isActiveFlag = consentContext?.activeSlide === id-1;
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Paper
      elevation={0}
      sx={{
        m: mobileScreen ? "0 0.25rem 0 0" : "0.25rem 0rem 0.25rem 0",
        flex: 1,
        height: "100%",
        // borderLeft: mobileScreen && id === 1 ? `15px solid ${theme.palette.secondary.main}` : null,
        maxWidth: mobileScreen ? "70vw" : "auto"
      }}>
      <CardActionArea
        sx={{
          boxShadow: isActiveFlag ? 5 : mobileScreen ? null : null,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: mobileScreen ? "1rem 0.625rem 0 0.625rem" : "1.25rem 2.75rem 0 1.5rem",
          borderLeft:
            !mobileScreen
              ? isActiveFlag
                ? `5px solid ${theme.palette.primary.main}`
                : `5px solid ${theme.palette.secondary.main}`
              : `5px solid ${theme.palette.secondary.main}`,
          borderTop:
            mobileScreen
              ? isActiveFlag
                ? `5px solid ${theme.palette.primary.main}`
                : `5px solid ${theme.palette.secondary.main}`
              : `5px solid ${theme.palette.secondary.main}`,
          backgroundColor: isActiveFlag ? theme.palette.primary.light : "white",
          borderBottom: mobileScreen ? "thin solid rgba(0, 0, 0, 0.12)" : 'none',
          gap: mobileScreen ? "0.5rem" : "0.75rem",
          width: "100%",
          borderBottomRightRadius: mobileScreen ? 0 : "inherit",
          borderBottomLeftRadius: mobileScreen ? 0 : "inherit",
        }}>
      
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Avatar sx={{
              ...sizeFontCustom.cardTitle,
              backgroundColor: customDetails?.primaryColor,
              height: mobileScreen ? "22px" : "28px",
              width: mobileScreen ? "22px" : "28px",
              mr: "0.678rem",
            }}>
              {id}
            </Avatar>
          </Box>
          <Typography sx={{
            ...sizeFontCustom.cardTitle,
            maxWidth: mobileScreen ? "60vw" : "19vw",
            fontWeight: 700,
            fontFamily: customDetails?.fontFamily,
            textAlign: "left",
          }}>
            {title}
          </Typography>
          </Box>
        <Divider variant="light" flexItem />
        <Stack component="ul" sx={{ pl: "1.5rem", mt: "0rem" }} height={"8rem"} spacing="0.5rem">
          <Typography sx={{
            ...sizeFontCustom.cardTitle,
            textAlign: "left",
          }}>
            {subtitle}
          </Typography>
          <Box textAlign={"start"}>
          {children}
          </Box>
        </Stack>
      </CardActionArea>
    </Paper>
  )
}

export default LspSteps;