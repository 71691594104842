/* eslint-disable react/prop-types */
import { Avatar, Box, Checkbox, Typography } from "@mui/material";
import { capitalize } from "../../hepler/functions";
import stylesLogo from "./Account.styles";
import sizeFontCustom from "../Discovery/fontSize.styles"

function AccountCheckBox(props) {
  // eslint-disable-next-line no-unused-vars
  const { acc, checkedItems, handleCheckboxChange, selectedAccounts, consent, superState, fip, ...rest } = props
  return (
    <Box
      onClick={(e) => handleCheckboxChange(e, acc)}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        alignItems: "flex-start",
        p: "1rem"
      }}
    >
      <Box sx={stylesLogo.container}>
        <Avatar
          alt={acc?.name}
          src={global.objectFormatFip[acc?.fipId]?.productIconUri}
          sx={stylesLogo.avatarStyle}
        />
        <Box textAlign={"left"}>
          <Typography sx={sizeFontCustom.subHeading} noWrap={false}>
            {global.objectFormatFip[acc?.fipId]?.productName}
          </Typography>

          <Typography sx={stylesLogo.account}>
            {capitalize(acc?.accType) +
              " " +
              acc?.maskedAccNumber?.replaceAll("X", "\u2022")}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Checkbox
          name={acc?.accRefNumber}
          size="small"
          sx={{ height: "18px", width: "18px" }}
          checked={consent? superState?.consentSelectedAccounts[consent.ConsentHandle]?.includes(acc): selectedAccounts?.includes(acc) || false}
        />
      </Box>
    </Box>
  );
}

export default AccountCheckBox;
