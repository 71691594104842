import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useContext } from 'react';
import ConsentListContext from '../Context/ConsentContext';

function SubmittingConsent() {
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const consentsContext = useContext(ConsentListContext)
  const consentsAll = consentsContext.consentList
  const consentsAccepted = consentsContext.acceptedConsentsMultiConsent
  const consentsDenied =  consentsContext.deniedConsentsMultiConsent
  const consentsActioned = consentsAccepted.length + consentsDenied.length +1
  const showCount = consentsAll.length>1 && global.institutionType !== "LSP"
  return (
    <Box
      sx={{
        flex: 2,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: mobileScreen ? "start" : "center",
        minHeight: '100vh',
        minWidth: { xs: "100vw", md: "auto", },
        background: theme.palette.primary.light,
        pt: mobileScreen ? '2rem' : "none",
      }}>
      <Typography sx={{
        fontSize: "1.25rem",
        fontWeight: 900,
        mb: "0.4rem"
      }}>
        {`Submitting Consent ${showCount?consentsActioned +  " of " + consentsAll.length: "" } ....`}
      </Typography>
      <Typography sx={{
        fontSize: "1rem",
        mb: "1.5rem",
        color: "#81858F"
      }}>
        Please wait while we are submitting your consent
      </Typography>
      <div className="dots-6"></div>
    </Box>
  )
}

export default SubmittingConsent