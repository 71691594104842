import { Box, Typography } from "@mui/material";
import sizeFontCustom from "../Discovery/fontSize.styles";

function ConsentDetailSingle(props) {
  // const theme = useTheme();
  const { title, description, flexDirection = "column", date = false, fromDate, toDate } = props;
  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: flexDirection }}>
      <Typography
        sx={{
          ...sizeFontCustom.cardText,
          // fontWeight: 700,
          textAlign: "start"
        }}
      >
        {title}
      </Typography>
      {date ?
        <>
          <Box sx={{ fontWeight: 400, textAlign: "start", display: "flex" }}>
            <Typography
              sx={{
                ...sizeFontCustom.cardText,
                // color: theme.palette.black60,
              }}
            >
              From &nbsp;
            </Typography>
            <Typography sx={{ ...sizeFontCustom.cardText }}>
              {fromDate} &nbsp;
            </Typography>
          </Box>
          <Box sx={{ fontWeight: 400, textAlign: "start", display: "flex" }}>
            <Typography
              sx={{
                ...sizeFontCustom.cardText,
                // color: theme.palette.black60,
              }}
            >
              To &nbsp;
            </Typography>
            <Typography sx={{ ...sizeFontCustom.cardText }}>
              {toDate}
            </Typography>
          </Box>
        </>
        :
        <Typography
          sx={{
            ...sizeFontCustom.cardText,
            fontWeight: 400,
            textAlign: "start",
          }}
        >
          {description}
        </Typography>
      }
    </Box>
  )
}

export default ConsentDetailSingle;
