import { Grid, useTheme } from "@mui/material";
import AccountCheckBox from "./AccountCheckBox";
import AddNewCard from "./AddNewCard";

function AccountCheckboxContainer(props) {
  const theme = useTheme();

  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"start"}
      gap={{ xs: "0.75rem", md: "1rem", lg: "1.9rem" }}
      width={"100%"}
      marginTop={"0.75rem"}
    >
      {props.requiredAccounts?.slice(props.start, props.end)?.map((acc,idx) => (
        <Grid
          item
          key={props.isZero ? idx : acc?.accRefNumber}
          xs={12}
          sm={3.5}
          margin={"0"}
          sx={{
            background: props.isZero ? null : `${theme.palette.primary.light}`,
            borderRadius: "20px",
          }}
        >
          {props.isZero ? (
            <AddNewCard fiType={acc} {...props} />
          ) : (
            <AccountCheckBox
              acc={acc}
              {...props}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default AccountCheckboxContainer