import { Avatar, Box, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material'
import stylesLogo from '../Accounts/Account.styles'
import sizeFontCustom from "./fontSize.styles"

function FipVerifiedHeading(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { fipId,  allLinked } = props
  return (
    <>
      <ListItem disablePadding>
        <ListItemIcon>
          <Avatar
            alt={""}
            src={global.objectFormatFip[fipId].productIconUri}
            sx={{ ...stylesLogo.avatarStyle, background: theme.palette.primary.light }} />
        </ListItemIcon>
        <ListItemText
          sx={{
            "& .MuiListItemText-primary": {
              color: theme.palette.info.main,
              ...sizeFontCustom.heading
            }
          }}
          primary={
            <Box
              flexDirection={mobileScreen ? "row" : "row"}
              alignItems={mobileScreen ? "flex-start" : "center"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem"
              }}>
              <span>{global.objectFormatFip[fipId].productName}</span>
              {/* {isVerified || allLinked ?
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem"
                  }}
                  color={theme.palette.success.main}>
                  <VerifiedIcon fontSize='small' /> Account Linked
                </Typography> : null} */}
            </Box>
          }
        />
      </ListItem>
      {props.areAccountsDiscovered &&
        <Typography
          textAlign={"start"}
          sx={sizeFontCustom.subHeading}
          color={theme.palette.black60}
          p={"0.125rem 0 0.25rem 3rem"}
        >
          {allLinked
            ? "All accounts with this institution are already linked"
            : `Accounts discovered with mobile number ${props.phoneNumber}`}
        </Typography>}
    </>
  )
}

export default FipVerifiedHeading;